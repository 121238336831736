import { IFieldPermissionResponse } from '../../../../../utils/getFieldPermission';

export type FinancialTotal = {
  totalCotPiShipment: number;
  totalSunPiShipment: number;
  totalCotPiShipmentInDollar: number;
  totalSunPiShipmentInDollar: number;
  countryOrigin?: string;
  currency?: string;
  mainProduct?: string;
  purchaseIncoterm?: string;
  totalCotCiShipment?: number;
  totalCotCiShipmentInDollar?: number;
  totalSunCiShipment?: number;
  totalSunCiShipmentInDollar?: number;
  estimatedGrossMargin?: number;
  grossMargin?: number;
  estimatedGrossProfit?: number;
  grossProfit?: number;
};

export type TotalPurchaseOtherExpensesTotal = {
  totalOtherExpenses: number;
  totalOnMargin: number;
  totalOutOfMargin: number;
  totalDiscountFromSupplier: number;
  totalPayToSupplier: number;
};

export type TotalSellerOtherExpensesTotal = {
  totalOtherExpenses: number;
  totalOnMargin: number;
  totalOutOfMargin: number;
  totalCreditToClient: number;
  totalForApportionment: number;
};

type PurchaseAdvancePaidTotal = {
  totalAmount: number;
  totalPercentage: number;
};

type SellerAdvanceReceivedTotal = {
  totalAmount: number;
  totalPercentage: number;
};

type PurchaseBalancePaidTotal = {
  totalAmount: number;
  totalPercentage: number;
};

type SellerBalanceReceivedTotal = {
  totalAmount: number;
  totalPercentage: number;
};

/* eslint-disable no-shadow */
export enum ForeignTradeSupplierTotalReducerActionKind {
  SET_FINANCIAL_TOTAL,
  CHANGE_FINANCIAL_TOTAL_LOADING,
  SET_PURCHASE_ADVANCE_PAID_TOTAL,
  CHANGE_PURCHASE_ADVANCE_PAID_TOTAL_LOADING,
  SET_PURCHASE_BALANCE_PAID_TOTAL,
  CHANGE_PURCHASE_BALANCE_PAID_TOTAL_LOADING,
  SET_PURCHASE_OTHER_EXPENSES_TOTAL,
  CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
  SET_SELLER_OTHER_EXPENSES_TOTAL,
  CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
  SET_SELLER_ADVANCE_RECEIVED_TOTAL,
  CHANGE_SELLER_ADVANCE_RECEIVED_TOTAL_LOADING,
  SET_SELLER_BALANCE_RECEIVED_TOTAL,
  CHANGE_SELLER_BALANCE_RECEIVED_TOTAL_LOADING,
}

export type ForeignTradeSupplierTotalReducer = {
  financialTotal?: FinancialTotal;
  financialTotalLoading?: boolean;
  purchaseAdvancePaidTotal?: PurchaseAdvancePaidTotal;
  purchaseAdvancePaidTotalLoading?: boolean;
  purchaseBalancePaidTotal?: PurchaseBalancePaidTotal;
  purchaseBalancePaidTotalLoading?: boolean;
  purchaseTotalPaid?: number;
  purchaseOtherExpensesTotal?: TotalPurchaseOtherExpensesTotal;
  purchaseOtherExpensesTotalLoading?: boolean;
  purchaseTotalRemainCot?: number;
  sellerOtherExpensesTotal?: TotalSellerOtherExpensesTotal;
  sellerOtherExpensesTotalLoading?: boolean;
  sellerTotalWithApportionment?: number;
  sellerAdvanceReceivedTotal?: SellerAdvanceReceivedTotal;
  sellerAdvanceReceivedTotalLoading?: boolean;
  sellerBalanceReceivedTotal?: SellerBalanceReceivedTotal;
  sellerBalanceReceivedTotalLoading?: boolean;
  sellerTotalReceived?: number;
  sellerTotalRemainSun?: number;
  estimatedNetMargin?: number;
  netMargin?: number;
  estimatedNetProfit?: number;
  netProfit?: number;
};

export type ForeignTradeSupplierTotalReducerAction = {
  type: ForeignTradeSupplierTotalReducerActionKind;
  payload?: {
    financialTotal?: FinancialTotal;
    purchaseAdvancePaidTotal?: PurchaseAdvancePaidTotal;
    purchaseBalancePaidTotal?: PurchaseBalancePaidTotal;
    purchaseOtherExpensesTotal?: TotalPurchaseOtherExpensesTotal;
    sellerOtherExpensesTotal?: TotalSellerOtherExpensesTotal;
    sellerAdvanceReceivedTotal?: SellerAdvanceReceivedTotal;
    sellerBalanceReceivedTotal?: SellerBalanceReceivedTotal;
    sunWithApportionmentTotalSum?: number;
  };
};

export type ForeignTradeSupplierTotalPurchaseOtherExpensesFieldsPermissions = {
  sunNumber: IFieldPermissionResponse;
  satForeignTradeNumber: IFieldPermissionResponse;
  satForeignTradeDestinationNumber: IFieldPermissionResponse;
};

export type ForeignTradeSupplierTotalSellerOtherExpensesFieldsPermissions = {
  sunNumber: IFieldPermissionResponse;
  satForeignTradeNumber: IFieldPermissionResponse;
  satForeignTradeDestinationNumber: IFieldPermissionResponse;
};

export type ForeignTradeSupplierTotalFieldsPermissions = {
  purchaseOtherExpenses: ForeignTradeSupplierTotalPurchaseOtherExpensesFieldsPermissions;
  sellerOtherExpenses: ForeignTradeSupplierTotalSellerOtherExpensesFieldsPermissions;
};
