import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { isEqual } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import { TabPanel } from 'primereact/tabview';
import React, {
  Dispatch,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { ValidationError } from 'yup';
import { useLocation } from 'react-router-dom';
import Empty from '../../../../components/Empty';
import PageTabContainer, {
  PageTabContainerProps,
} from '../../../../components/PageTabContainer';
import TabViewAsync, {
  ITabViewAsyncPageParams,
} from '../../../../components/TabViewAsync';
import pagination from '../../../../config/pagination';
import { useRefHook } from '../../../../hooks/useRefHook';
import { DomainGroup } from '../../../../shared/enums/domainGroup';
import { ForeignTradeTypeFiles } from '../../../../shared/enums/domains';
import ToastLife from '../../../../shared/enums/toastLife';
import {
  satFinancialAdvancePaidRoles,
  satFinancialAdvanceReceivedRoles,
  satFinancialBalancePaidRoles,
  satFinancialBalanceReceivedRoles,
  satFinancialFilesPurchaseRoles,
  satFinancialFilesSellerRoles,
  satFinancialPurchaseOtherExpensesRoles,
  satFinancialPurchaseOtherExpensesTotalRoles,
  satFinancialResultsConvertedRoles,
  satFinancialResultsGrossRoles,
  satFinancialResultsNetRoles,
  satFinancialResultsOtherExpensesRoles,
  satFinancialResultsRoles,
  satFinancialResultsStatusRoles,
  satFinancialRoles,
  satFinancialSellerOtherExpensesRoles,
  satFinancialSellerOtherExpensesTotalRoles,
  satFinancialTotalPaidRoles,
  satFinancialTotalReceivedRoles,
  satFinancialUsersPurchaseRoles,
  satFinancialUsersSellerRoles,
  satsRoles,
} from '../../../../shared/roles/sat';
import getFieldPermission from '../../../../utils/getFieldPermission';
import { IUserFieldsAndPermissionsResponse } from '../../../../utils/getUserFieldsAndPermissionsByEntity';
import getValidationErrors, {
  getIndexPositionFromErrorPath,
} from '../../../../utils/getValidationErrors';
import { objectsAreEqual } from '../../../../utils/objectsAreEqual';
import userHasPermission from '../../../../utils/userHasPermission';
import { ISat } from '../interfaces';
import { listDomainsByGroupIdQuery } from '../Rnc/queries';
import Content from './Content';
import {
  FinancialFieldsCommonWithSatFieldPermissions,
  FinancialFieldsPermissions,
  FinancialFormData,
  ForeignTradeSupplierReducerActionKind,
  SatFinancialAdvancePaidFieldsPermissions,
  SatFinancialAdvancePaidPermissions,
  SatFinancialAdvanceReceivedFieldsPermissions,
  SatFinancialBalanceFieldsPermissions,
  SatFinancialFilesPermissions,
  SatFinancialPurchaseOtherExpenseFieldsPermissions,
  SatFinancialPurchaseOtherExpensesTotalFieldsPermissions,
  SatFinancialResultsPermissions,
  SatFinancialSellerOtherExpenseFieldsPermissions,
  SatFinancialSellerOtherExpensesTotalFieldsPermissions,
  SatFinancialTotalFieldsPermissions,
  SatFinancialUsersPurchaseFieldsPermissions,
  SatFinancialUsersSellerFieldsPermissions,
  SatForeignTradeSupplier,
  UpdateSatForeignTradeSuppliersResponse,
  UserCanObject,
} from './interfaces';
import { listDomainById, updateSatForeignTradeSuppliersQuery } from './queries';
import {
  foreignTradeSupplierReducerInitialState,
  foreignTradeSuppliersReducer,
} from './reducers';
import Total from './Total';
import { ISatFinancialApportionmentRef } from '../FinancialApportionment';

export interface ISatFinancialRef {
  validateForm(): Promise<boolean>;
  submitForm(): Promise<UpdateSatForeignTradeSuppliersResponse>;
  refreshForm(): void;
}

interface IFinancialProps extends PageTabContainerProps {
  ref: Ref<ISatFinancialRef>;
  sat: ISat;
  pageMenuItemKey: string;
  setSelectedMenuItem: Dispatch<React.SetStateAction<string>>;
  userPermissions: IUserFieldsAndPermissionsResponse;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
  apportionmentRef: RefObject<ISatFinancialApportionmentRef>;
  isSatCancelled: boolean;
}

const Financial: React.FC<IFinancialProps> = React.forwardRef(
  (
    {
      selected,
      sat,
      pageMenuItemKey,
      setSelectedMenuItem,
      userPermissions,
      commonWithSatFieldsPermissions,
      apportionmentRef,
      isSatCancelled,
    },
    ref,
  ) => {
    const { toastRef } = useRefHook();

    const { search } = useLocation();

    const urlParam = new URLSearchParams(search).get(
      'satForeignTradeSupplierId',
    );

    const userCanSeeTotalFinancial = userHasPermission(
      satsRoles.permissions.idPermissionTotalFinancial,
      userPermissions.userPermissions,
    );

    const [foreignTradeSuppliersState, foreignTradeSuppliersDispatch] =
      useReducer(
        foreignTradeSuppliersReducer,
        foreignTradeSupplierReducerInitialState,
      );

    const [updateSatForeignTradeSuppliersMutation] = useMutation(
      updateSatForeignTradeSuppliersQuery,
    );

    const formRef = useRef<FormHandles>(null);

    const fieldsPermissions: FinancialFieldsPermissions = useMemo(() => {
      return {
        idStatusPurchasePayment: getFieldPermission(
          satFinancialRoles.fields.idFieldIdStatusPurchasePayment,
          userPermissions.userFields,
        ),
        sunNumber: getFieldPermission(
          satFinancialRoles.fields.idFieldSunNumber,
          userPermissions.userFields,
        ),
        supplierName: getFieldPermission(
          satFinancialRoles.fields.idFieldSupplierName,
          userPermissions.userFields,
        ),
        idStatusSellingPayment: getFieldPermission(
          satFinancialRoles.fields.idFieldIdStatusSellingPayment,
          userPermissions.userFields,
        ),
        exchangeRate: getFieldPermission(
          satFinancialRoles.fields.idFieldExchangeRate,
          userPermissions.userFields,
        ),
        realInspectionDate: getFieldPermission(
          satFinancialRoles.fields.idFieldRealInspectionDate,
          userPermissions.userFields,
        ),
        idImportStatus: getFieldPermission(
          satFinancialRoles.fields.idFieldIdImportStatus,
          userPermissions.userFields,
        ),
        realEta: getFieldPermission(
          satFinancialRoles.fields.idFieldRealEta,
          userPermissions.userFields,
        ),
        realEtd: getFieldPermission(
          satFinancialRoles.fields.idFieldRealEtd,
          userPermissions.userFields,
        ),
        purchaseCiCurrency: getFieldPermission(
          satFinancialRoles.fields.idFieldPurchaseCiCurrency,
          userPermissions.userFields,
        ),
        purchasePiCurrency: getFieldPermission(
          satFinancialRoles.fields.idFieldPurchasePiCurrency,
          userPermissions.userFields,
        ),
        totalCotPiShipment: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalCotPiShipment,
          userPermissions.userFields,
        ),
        totalConvertedCotCi: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalConvertedCotCi,
          userPermissions.userFields,
        ),
        totalConvertedCotPi: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalConvertedCotPi,
          userPermissions.userFields,
        ),
        totalCotCiShipment: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalCotCiShipment,
          userPermissions.userFields,
        ),
        estimatedPaymentAdvance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentAdvance,
          userPermissions.userFields,
        ),
        estimatedPaymentTotalAdvance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentTotalAdvance,
          userPermissions.userFields,
        ),
        estimatedPaymentAdvanceRequest: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentAdvanceRequest,
          userPermissions.userFields,
        ),
        estimatedPaymentBalance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentBalance,
          userPermissions.userFields,
        ),
        estimatedPaymentTotalBalance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentTotalBalance,
          userPermissions.userFields,
        ),
        estimatedPaymentBalanceRequest: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentBalanceRequest,
          userPermissions.userFields,
        ),
        estimatedPaymentTermCondition: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedPaymentTermCondition,
          userPermissions.userFields,
        ),
        estimatedReceiveAdvance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveAdvance,
          userPermissions.userFields,
        ),
        estimatedReceiveTotalAdvance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveTotalAdvance,
          userPermissions.userFields,
        ),
        estimatedReceiveAdvanceRequest: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveAdvanceRequest,
          userPermissions.userFields,
        ),
        estimatedReceiveBalance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveBalance,
          userPermissions.userFields,
        ),
        estimatedReceiveTotalBalance: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveTotalBalance,
          userPermissions.userFields,
        ),
        estimatedReceiveBalanceRequest: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveBalanceRequest,
          userPermissions.userFields,
        ),
        estimatedReceiveTermCondition: getFieldPermission(
          satFinancialRoles.fields.idFieldEstimatedReceiveTermCondition,
          userPermissions.userFields,
        ),
        sellerCiCurrency: getFieldPermission(
          satFinancialRoles.fields.idFieldSellerCiCurrency,
          userPermissions.userFields,
        ),
        totalSunPiShipment: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalSunPiShipment,
          userPermissions.userFields,
        ),
        sellerPiCurrency: getFieldPermission(
          satFinancialRoles.fields.idFieldSellerPiCurrency,
          userPermissions.userFields,
        ),
        totalConvertedSunCi: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalConvertedSunCi,
          userPermissions.userFields,
        ),
        totalConvertedSunPi: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalConvertedSunPi,
          userPermissions.userFields,
        ),
        totalSunCiShipment: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalSunCiShipment,
          userPermissions.userFields,
        ),
        totalWithApportionment: getFieldPermission(
          satFinancialRoles.fields.idFieldTotalWithApportionment,
          userPermissions.userFields,
        ),
      };
    }, [userPermissions.userFields]);

    const purchaseOtherExpensesFieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions =
      useMemo(() => {
        return {
          idType: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields
              .idFieldTypeDescription,
            userPermissions.userFields,
          ),
          total: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields.idFieldTotal,
            userPermissions.userFields,
          ),
          dateOtherExpense: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields
              .idFieldDateOtherExpense,
            userPermissions.userFields,
          ),
          linkToMargin: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields.idFieldLinkToMargin,
            userPermissions.userFields,
          ),
          discountFromSupplier: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields
              .idFieldDiscountFromSupplier,
            userPermissions.userFields,
          ),
          payToSupplier: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields.idFieldPayToSupplier,
            userPermissions.userFields,
          ),
          comment: getFieldPermission(
            satFinancialPurchaseOtherExpensesRoles.fields.idFieldComment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const sellerOtherExpensesFieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions =
      useMemo(() => {
        return {
          idType: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldTypeDescription,
            userPermissions.userFields,
          ),
          total: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldTotal,
            userPermissions.userFields,
          ),
          dateOtherExpense: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldDateOtherExpense,
            userPermissions.userFields,
          ),
          linkToMargin: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldLinkToMargin,
            userPermissions.userFields,
          ),
          creditToClient: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldCreditToClient,
            userPermissions.userFields,
          ),
          apportionment: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldApportionment,
            userPermissions.userFields,
          ),
          calculationBasis: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldCalculationBasis,
            userPermissions.userFields,
          ),
          comment: getFieldPermission(
            satFinancialSellerOtherExpensesRoles.fields.idFieldComment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const advancePaidFieldsPermissions: SatFinancialAdvancePaidFieldsPermissions =
      useMemo(() => {
        return {
          percentage: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldPercentage,
            userPermissions.userFields,
          ),
          amount: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldAmount,
            userPermissions.userFields,
          ),
          paymentDate: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldPaymentDate,
            userPermissions.userFields,
          ),
          rollingFrom: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldRollingFrom,
            userPermissions.userFields,
          ),
          rollingTo: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldRollingTo,
            userPermissions.userFields,
          ),
          shipment: getFieldPermission(
            satFinancialAdvancePaidRoles.fields.idFieldShipment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const advancePaidPermissions: SatFinancialAdvancePaidPermissions =
      useMemo(() => {
        return {
          addItem:
            userHasPermission(
              satFinancialAdvancePaidRoles.permissions.idPermissionAddItem,
              userPermissions.userPermissions,
            ) && !isSatCancelled,
          addRolling:
            userHasPermission(
              satFinancialAdvancePaidRoles.permissions.idPermissionAddRolling,
              userPermissions.userPermissions,
            ) && !isSatCancelled,
          updateItem:
            userHasPermission(
              satFinancialAdvancePaidRoles.permissions.idPermissionUpdateItem,
              userPermissions.userPermissions,
            ) && !isSatCancelled,
          removeItem:
            userHasPermission(
              satFinancialAdvancePaidRoles.permissions.idPermissionRemoveItem,
              userPermissions.userPermissions,
            ) && !isSatCancelled,
          requestPayment:
            userHasPermission(
              satFinancialAdvancePaidRoles.permissions
                .idPermissionRequestPayment,
              userPermissions.userPermissions,
            ) && !isSatCancelled,
        };
      }, [isSatCancelled, userPermissions.userPermissions]);

    const advanceReceivedFieldsPermissions: SatFinancialAdvanceReceivedFieldsPermissions =
      useMemo(() => {
        return {
          percentage: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldPercentage,
            userPermissions.userFields,
          ),
          amount: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldAmount,
            userPermissions.userFields,
          ),
          paymentDate: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldPaymentDate,
            userPermissions.userFields,
          ),
          rollingFrom: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldRollingFrom,
            userPermissions.userFields,
          ),
          rollingTo: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldRollingTo,
            userPermissions.userFields,
          ),
          shipment: getFieldPermission(
            satFinancialAdvanceReceivedRoles.fields.idFieldShipment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const advanceReceivedPermissions = useMemo(() => {
      return {
        addItem:
          userHasPermission(
            satFinancialAdvanceReceivedRoles.permissions.idPermissionAddItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        addRolling:
          userHasPermission(
            satFinancialAdvanceReceivedRoles.permissions.idPermissionAddRolling,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        updateItem:
          userHasPermission(
            satFinancialAdvanceReceivedRoles.permissions.idPermissionUpdateItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        removeItem:
          userHasPermission(
            satFinancialAdvanceReceivedRoles.permissions.idPermissionRemoveItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
      };
    }, [isSatCancelled, userPermissions.userPermissions]);

    const balancePaidFieldsPermissions: SatFinancialBalanceFieldsPermissions =
      useMemo(() => {
        return {
          percentage: getFieldPermission(
            satFinancialBalancePaidRoles.fields.idFieldPercentage,
            userPermissions.userFields,
          ),
          amount: getFieldPermission(
            satFinancialBalancePaidRoles.fields.idFieldAmount,
            userPermissions.userFields,
          ),
          paymentDate: getFieldPermission(
            satFinancialBalancePaidRoles.fields.idFieldPaymentDate,
            userPermissions.userFields,
          ),
          shipment: getFieldPermission(
            satFinancialBalancePaidRoles.fields.idFieldShipment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const balancePaidPermissions = useMemo(() => {
      return {
        addItem:
          userHasPermission(
            satFinancialBalancePaidRoles.permissions.idPermissionAddItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        updateItem:
          userHasPermission(
            satFinancialBalancePaidRoles.permissions.idPermissionUpdateItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        removeItem:
          userHasPermission(
            satFinancialBalancePaidRoles.permissions.idPermissionRemoveItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
      };
    }, [isSatCancelled, userPermissions.userPermissions]);

    const balanceReceivedFieldsPermissions: SatFinancialBalanceFieldsPermissions =
      useMemo(() => {
        return {
          percentage: getFieldPermission(
            satFinancialBalanceReceivedRoles.fields.idFieldPercentage,
            userPermissions.userFields,
          ),
          amount: getFieldPermission(
            satFinancialBalanceReceivedRoles.fields.idFieldAmount,
            userPermissions.userFields,
          ),
          paymentDate: getFieldPermission(
            satFinancialBalanceReceivedRoles.fields.idFieldPaymentDate,
            userPermissions.userFields,
          ),
          shipment: getFieldPermission(
            satFinancialBalanceReceivedRoles.fields.idFieldShipment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const balanceReceivedPermissions = useMemo(() => {
      return {
        addItem:
          userHasPermission(
            satFinancialBalanceReceivedRoles.permissions.idPermissionAddItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        updateItem:
          userHasPermission(
            satFinancialBalanceReceivedRoles.permissions.idPermissionUpdateItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        removeItem:
          userHasPermission(
            satFinancialBalanceReceivedRoles.permissions.idPermissionRemoveItem,
            userPermissions.userPermissions,
          ) && !isSatCancelled,
        requestPayment: false,
      };
    }, [isSatCancelled, userPermissions.userPermissions]);

    const totalPaidFieldsPermissions: SatFinancialTotalFieldsPermissions =
      useMemo(() => {
        return {
          total: getFieldPermission(
            satFinancialTotalPaidRoles.fields.totalPaid,
            userPermissions.userFields,
          ),
          totalRemain: getFieldPermission(
            satFinancialTotalPaidRoles.fields.totalRemainCot,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const totalReceivedFieldsPermissions: SatFinancialTotalFieldsPermissions =
      useMemo(() => {
        return {
          total: getFieldPermission(
            satFinancialTotalReceivedRoles.fields.totalReceived,
            userPermissions.userFields,
          ),
          totalRemain: getFieldPermission(
            satFinancialTotalReceivedRoles.fields.totalRemainSun,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const usersPurchaseFieldsPermissions: SatFinancialUsersPurchaseFieldsPermissions =
      useMemo(() => {
        return {
          idPurchaseUser: getFieldPermission(
            satFinancialUsersPurchaseRoles.fields.idPurchaseUser,
            userPermissions.userFields,
          ),
          idComexExportUser: getFieldPermission(
            satFinancialUsersPurchaseRoles.fields.idComexExportUser,
            userPermissions.userFields,
          ),
          idFinancialUser: getFieldPermission(
            satFinancialUsersPurchaseRoles.fields.idFinancialUser,
            userPermissions.userFields,
          ),
          notes: getFieldPermission(
            satFinancialUsersPurchaseRoles.fields.notes,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const purchaseOtherExpensesTotalFieldsPermissions: SatFinancialPurchaseOtherExpensesTotalFieldsPermissions =
      useMemo(() => {
        return {
          totalOtherExpenses: getFieldPermission(
            satFinancialPurchaseOtherExpensesTotalRoles.fields
              .idFieldTotalOtherExpenses,
            userPermissions.userFields,
          ),
          totalOnMargin: getFieldPermission(
            satFinancialPurchaseOtherExpensesTotalRoles.fields
              .idFieldTotalOnMargin,
            userPermissions.userFields,
          ),
          totalOutOfMargin: getFieldPermission(
            satFinancialPurchaseOtherExpensesTotalRoles.fields
              .idFieldTotalOutOfMargin,
            userPermissions.userFields,
          ),
          totalDiscountFromSupplier: getFieldPermission(
            satFinancialPurchaseOtherExpensesTotalRoles.fields
              .idFieldTotalDiscountFromSupplier,
            userPermissions.userFields,
          ),
          totalPayToSupplier: getFieldPermission(
            satFinancialPurchaseOtherExpensesTotalRoles.fields
              .idFieldTotalPayToSupplier,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const usersSellerFieldsPermissions: SatFinancialUsersSellerFieldsPermissions =
      useMemo(() => {
        return {
          idSalesUser: getFieldPermission(
            satFinancialUsersSellerRoles.fields.idSalesUser,
            userPermissions.userFields,
          ),
          idSalesSecondUser: getFieldPermission(
            satFinancialUsersSellerRoles.fields.idSalesSecondUser,
            userPermissions.userFields,
          ),
          idComexImportUser: getFieldPermission(
            satFinancialUsersSellerRoles.fields.idComexImportUser,
            userPermissions.userFields,
          ),
          sellerNotes: getFieldPermission(
            satFinancialUsersSellerRoles.fields.sellerNotes,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const sellerOtherExpensesTotalFieldsPermissions: SatFinancialSellerOtherExpensesTotalFieldsPermissions =
      useMemo(() => {
        return {
          totalOtherExpenses: getFieldPermission(
            satFinancialSellerOtherExpensesTotalRoles.fields
              .idFieldTotalOtherExpenses,
            userPermissions.userFields,
          ),
          totalOnMargin: getFieldPermission(
            satFinancialSellerOtherExpensesTotalRoles.fields
              .idFieldTotalOnMargin,
            userPermissions.userFields,
          ),
          totalOutOfMargin: getFieldPermission(
            satFinancialSellerOtherExpensesTotalRoles.fields
              .idFieldTotalOutOfMargin,
            userPermissions.userFields,
          ),
          totalCreditToClient: getFieldPermission(
            satFinancialSellerOtherExpensesTotalRoles.fields
              .idFieldTotalCreditToClient,
            userPermissions.userFields,
          ),
          totalForApportionment: getFieldPermission(
            satFinancialSellerOtherExpensesTotalRoles.fields
              .idFieldTotalForApportionment,
            userPermissions.userFields,
          ),
        };
      }, [userPermissions.userFields]);

    const purchaseFilesPermissions: SatFinancialFilesPermissions =
      useMemo(() => {
        return {
          upload: userHasPermission(
            satFinancialFilesPurchaseRoles.permissions.idPermissionUpload,
            userPermissions.userPermissions,
          ),
          delete: userHasPermission(
            satFinancialFilesPurchaseRoles.permissions.idPermissionDelete,
            userPermissions.userPermissions,
          ),
        };
      }, [userPermissions.userPermissions]);

    const sellerFilesPermissions: SatFinancialFilesPermissions = useMemo(() => {
      return {
        upload: userHasPermission(
          satFinancialFilesSellerRoles.permissions.idPermissionUpload,
          userPermissions.userPermissions,
        ),
        delete: userHasPermission(
          satFinancialFilesSellerRoles.permissions.idPermissionDelete,
          userPermissions.userPermissions,
        ),
      };
    }, [userPermissions.userPermissions]);

    const resultsFieldsPermissions: SatFinancialResultsPermissions =
      useMemo(() => {
        return {
          mainFieldsPermissions: {
            totalCotPiShipment: getFieldPermission(
              satFinancialResultsRoles.fields.idFieldTotalCotPiShipment,
              userPermissions.userFields,
            ),
            totalCotCiShipment: getFieldPermission(
              satFinancialResultsRoles.fields.idFieldTotalCotCiShipment,
              userPermissions.userFields,
            ),
            totalSunPiShipment: getFieldPermission(
              satFinancialResultsRoles.fields.idFieldTotalSunPiShipment,
              userPermissions.userFields,
            ),
            totalSunCiShipment: getFieldPermission(
              satFinancialResultsRoles.fields.idFieldTotalSunCiShipment,
              userPermissions.userFields,
            ),
            totalWithApportionment: getFieldPermission(
              satFinancialResultsRoles.fields.idFieldTotalWithApportionment,
              userPermissions.userFields,
            ),
          },
          convertedFieldsPermissions: {
            totalConvertedCotPi: getFieldPermission(
              satFinancialResultsConvertedRoles.fields
                .idFieldTotalConvertedCotPi,
              userPermissions.userFields,
            ),
            totalConvertedCotCi: getFieldPermission(
              satFinancialResultsConvertedRoles.fields
                .idFieldTotalConvertedCotCi,
              userPermissions.userFields,
            ),
            totalConvertedSunPi: getFieldPermission(
              satFinancialResultsConvertedRoles.fields
                .idFieldTotalConvertedSunPi,
              userPermissions.userFields,
            ),
            totalConvertedSunCi: getFieldPermission(
              satFinancialResultsConvertedRoles.fields
                .idFieldTotalConvertedSunCi,
              userPermissions.userFields,
            ),
          },
          grossFieldsPermissions: {
            estimatedGrossMargin: getFieldPermission(
              satFinancialResultsGrossRoles.fields.idFieldEstimatedGrossMargin,
              userPermissions.userFields,
            ),
            grossMargin: getFieldPermission(
              satFinancialResultsGrossRoles.fields.idFieldGrossMargin,
              userPermissions.userFields,
            ),
            estimatedGrossProfit: getFieldPermission(
              satFinancialResultsGrossRoles.fields.idFieldEstimatedGrossProfit,
              userPermissions.userFields,
            ),
            grossProfit: getFieldPermission(
              satFinancialResultsGrossRoles.fields.idFieldGrossProfit,
              userPermissions.userFields,
            ),
          },
          otherExpensesFieldsPermissions: {
            purchaseTotalOtherExpenses: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldPurchaseTotalOtherExpenses,
              userPermissions.userFields,
            ),
            purchaseTotalOnMargin: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldPurchaseTotalOnMargin,
              userPermissions.userFields,
            ),
            purchaseTotalOutOfMargin: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldPurchaseTotalOutOfMargin,
              userPermissions.userFields,
            ),
            sellerTotalOtherExpenses: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldSellerTotalOtherExpenses,
              userPermissions.userFields,
            ),
            sellerTotalOnMargin: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldSellerTotalOnMargin,
              userPermissions.userFields,
            ),
            sellerTotalOutOfMargin: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldSellerTotalOutOfMargin,
              userPermissions.userFields,
            ),
            sellerTotalForApportionment: getFieldPermission(
              satFinancialResultsOtherExpensesRoles.fields
                .idFieldSellerTotalForApportionment,
              userPermissions.userFields,
            ),
          },
          netFieldsPermissions: {
            estimatedNetMargin: getFieldPermission(
              satFinancialResultsNetRoles.fields.idFieldEstimatedNetMargin,
              userPermissions.userFields,
            ),
            netMargin: getFieldPermission(
              satFinancialResultsNetRoles.fields.idFieldNetMargin,
              userPermissions.userFields,
            ),
            estimatedNetProfit: getFieldPermission(
              satFinancialResultsNetRoles.fields.idFieldEstimatedNetProfit,
              userPermissions.userFields,
            ),
            netProfit: getFieldPermission(
              satFinancialResultsNetRoles.fields.idFieldNetProfit,
              userPermissions.userFields,
            ),
          },
          statusFieldsPermissions: {
            shipmentStatus: getFieldPermission(
              satFinancialResultsStatusRoles.fields.idFieldShipmentStatus,
              userPermissions.userFields,
            ),
          },
        };
      }, [userPermissions.userFields]);

    const userCanObjects: UserCanObject = {
      userCanSeePurchaseBlock:
        fieldsPermissions.idStatusPurchasePayment.view ||
        commonWithSatFieldsPermissions.countryOriginName.view ||
        commonWithSatFieldsPermissions.purchaseIncotermDescription.view ||
        commonWithSatFieldsPermissions.mainProduct.view ||
        fieldsPermissions.sunNumber.view ||
        fieldsPermissions.supplierName.view,
      userCanSeeSellerBlock:
        fieldsPermissions.idStatusSellingPayment.view ||
        commonWithSatFieldsPermissions.companyName.view ||
        commonWithSatFieldsPermissions.importerName.view ||
        fieldsPermissions.exchangeRate.view ||
        commonWithSatFieldsPermissions.idClientIncoterm.view ||
        fieldsPermissions.idImportStatus.view ||
        fieldsPermissions.realInspectionDate.view ||
        fieldsPermissions.realEtd.view ||
        fieldsPermissions.realEta.view,
      userCanSeePurchasePiBlock:
        fieldsPermissions.purchasePiCurrency.view ||
        fieldsPermissions.totalCotPiShipment.view ||
        fieldsPermissions.totalConvertedCotPi.view,
      userCanSeePurchaseCiBlock:
        fieldsPermissions.purchaseCiCurrency.view ||
        fieldsPermissions.totalCotCiShipment.view ||
        fieldsPermissions.totalConvertedCotCi.view,
      userCanSeeEstimatedPaymentPI:
        fieldsPermissions.estimatedPaymentAdvance.view ||
        fieldsPermissions.estimatedPaymentTotalAdvance.view ||
        fieldsPermissions.estimatedPaymentAdvanceRequest.view ||
        fieldsPermissions.estimatedPaymentBalance.view ||
        fieldsPermissions.estimatedPaymentTotalBalance.view ||
        fieldsPermissions.estimatedPaymentBalanceRequest.view ||
        fieldsPermissions.estimatedPaymentTermCondition.view,
      userCanSeeEstimatedReceive:
        fieldsPermissions.estimatedReceiveAdvance.view ||
        fieldsPermissions.estimatedReceiveTotalAdvance.view ||
        fieldsPermissions.estimatedReceiveAdvanceRequest.view ||
        fieldsPermissions.estimatedReceiveBalance.view ||
        fieldsPermissions.estimatedReceiveTotalBalance.view ||
        fieldsPermissions.estimatedReceiveBalanceRequest.view ||
        fieldsPermissions.estimatedReceiveTermCondition.view,
      userCanSeeSellerPiBlock:
        fieldsPermissions.sellerPiCurrency.view ||
        fieldsPermissions.totalSunPiShipment.view ||
        fieldsPermissions.totalConvertedSunPi.view,
      userCanSeeSellerCiBlock:
        fieldsPermissions.sellerCiCurrency.view ||
        fieldsPermissions.totalSunCiShipment.view ||
        fieldsPermissions.totalConvertedSunCi.view ||
        fieldsPermissions.totalWithApportionment.view,
      userCanSeePurchaseOtherExpensesBlock: Object.keys(
        purchaseOtherExpensesFieldsPermissions,
      ).some(key => purchaseOtherExpensesFieldsPermissions[key].view),
      userCanSeeSellerOtherExpensesBlock: Object.keys(
        sellerOtherExpensesFieldsPermissions,
      ).some(key => sellerOtherExpensesFieldsPermissions[key].view),
      userCanAddPurchaseOtherExpenses:
        userHasPermission(
          satFinancialPurchaseOtherExpensesRoles.permissions
            .idPermissionAddItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanEditPurchaseOtherExpenses:
        userHasPermission(
          satFinancialPurchaseOtherExpensesRoles.permissions
            .idPermissionEditItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanDeletePurchaseOtherExpenses:
        userHasPermission(
          satFinancialPurchaseOtherExpensesRoles.permissions
            .idPermissionRemoveItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanAddSellerOtherExpenses:
        userHasPermission(
          satFinancialSellerOtherExpensesRoles.permissions.idPermissionAddItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanEditSellerOtherExpenses:
        userHasPermission(
          satFinancialSellerOtherExpensesRoles.permissions.idPermissionEditItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanDeleteSellerOtherExpenses:
        userHasPermission(
          satFinancialSellerOtherExpensesRoles.permissions
            .idPermissionRemoveItem,
          userPermissions.userPermissions,
        ) && !isSatCancelled,
      userCanSeeTotalPaid:
        totalPaidFieldsPermissions.total.view ||
        totalPaidFieldsPermissions.totalRemain.view,
      userCanSeeTotalReceived:
        totalReceivedFieldsPermissions.total.view ||
        totalReceivedFieldsPermissions.totalRemain.view,
      userCanSeeUsersPurchase:
        usersPurchaseFieldsPermissions.idPurchaseUser.view ||
        usersPurchaseFieldsPermissions.idComexExportUser.view ||
        usersPurchaseFieldsPermissions.idFinancialUser.view ||
        usersPurchaseFieldsPermissions.notes.view,
      userCanSeeUsersSeller:
        usersSellerFieldsPermissions.idSalesUser.view ||
        usersSellerFieldsPermissions.idSalesSecondUser.view ||
        usersSellerFieldsPermissions.idComexImportUser.view ||
        usersSellerFieldsPermissions.sellerNotes.view,
      userCanSeePurchaseOtherExpensesTotalBlock: Object.keys(
        purchaseOtherExpensesTotalFieldsPermissions,
      ).some(key => purchaseOtherExpensesTotalFieldsPermissions[key].view),
      userCanSeeSellerOtherExpensesTotalBlock: Object.keys(
        sellerOtherExpensesTotalFieldsPermissions,
      ).some(key => sellerOtherExpensesTotalFieldsPermissions[key].view),
      userCanResultsBlock: {
        userCanSeeResultsBlock: Object.keys(
          resultsFieldsPermissions.mainFieldsPermissions,
        ).some(key => resultsFieldsPermissions.mainFieldsPermissions[key].view),
        userCanSeeResultsConvertedBlock: Object.keys(
          resultsFieldsPermissions.convertedFieldsPermissions,
        ).some(
          key => resultsFieldsPermissions.convertedFieldsPermissions[key].view,
        ),
        userCanSeeResultsGrossBlock: Object.keys(
          resultsFieldsPermissions.grossFieldsPermissions,
        ).some(
          key => resultsFieldsPermissions.grossFieldsPermissions[key].view,
        ),
        userCanSeeResultsOtherExpensesBlock: Object.keys(
          resultsFieldsPermissions.otherExpensesFieldsPermissions,
        ).some(
          key =>
            resultsFieldsPermissions.otherExpensesFieldsPermissions[key].view,
        ),
        userCanSeeResultsNetBlock: Object.keys(
          resultsFieldsPermissions.netFieldsPermissions,
        ).some(key => resultsFieldsPermissions.netFieldsPermissions[key].view),
        userCanSeeResultsStatusBlock: Object.keys(
          resultsFieldsPermissions.statusFieldsPermissions,
        ).some(
          key => resultsFieldsPermissions.statusFieldsPermissions[key].view,
        ),
      },
      userCanAccessApportionment: userHasPermission(
        satFinancialPurchaseOtherExpensesTotalRoles.permissions
          .idPermissionApportionment,
        userPermissions.userPermissions,
      ),
    };

    const listSatForeignTradeSuppliersQuery = gql`
    query ListSatForeignTradeSuppliersBySatId(
      $data: ListSatForeignTradeSuppliersBySatIdInput!
    ) {
      listSatForeignTradeSuppliersBySatId(data: $data) {
        items
        data {
          idSat
          idSatForeignTrade
          idSatForeignTradeSupplier
          satForeignTradeSupplierNumber
          advancePaymentRequest
          balancePaymentRequest
          notes
          sellerNotes
          totalCotPiShipment
          totalSunPiShipment
          ${
            fieldsPermissions.idStatusPurchasePayment.view
              ? `idStatusPurchasePayment
              idStatusPurchasePayment2 {
                idDomain
                description
              }
              `
              : ''
          }
          idSupplier
          idSupplier2 {
            idSupplier
            ${fieldsPermissions.sunNumber.view ? 'sunNumber' : ''}
            ${fieldsPermissions.supplierName.view ? 'name' : ''}
          }
          ${
            fieldsPermissions.idStatusSellingPayment.view
              ? `
              idStatusSellingPayment
              idStatusSellingPayment2 {
                idDomain
                description
              }`
              : ''
          }
          ${fieldsPermissions.exchangeRate.view ? 'exchangeRate' : ''}
          idSatForeignTrade2 {
            idSatForeignTrade
            ${
              fieldsPermissions.realInspectionDate.view
                ? 'realInspectionDate'
                : ''
            }
            ${fieldsPermissions.realEtd.view ? 'realEtd' : ''}
            ${fieldsPermissions.realEta.view ? 'realEta' : ''}
            ${
              fieldsPermissions.idImportStatus.view
                ? `
                idImportStatus2 {
                  idDomain
                  description
                }
                `
                : ''
            }
            shipmentStatus
          }
          ${
            fieldsPermissions.estimatedPaymentAdvance.view
              ? 'estimatedAdvance'
              : ''
          }
          ${
            fieldsPermissions.estimatedPaymentAdvanceRequest.view
              ? 'estimatedAdvanceRequest'
              : ''
          }
          ${
            fieldsPermissions.estimatedPaymentBalanceRequest.view
              ? 'estimatedBalanceRequest'
              : ''
          }
          ${
            fieldsPermissions.estimatedPaymentTermCondition.view
              ? `estimatedTermCondition
                estimatedTermCondition2 {
                  idDomain
                  description
                }`
              : ''
          }
          totalCotCiShipment
          totalSunCiShipment
          ${
            fieldsPermissions.estimatedReceiveAdvanceRequest.view
              ? 'sellerEstimatedAdvanceRequest'
              : ''
          }
          ${
            fieldsPermissions.estimatedReceiveBalanceRequest.view
              ? 'sellerEstimatedBalanceRequest'
              : ''
          }
          ${
            fieldsPermissions.estimatedReceiveTermCondition.view
              ? `sellerEstimatedTermCondition
                 sellerEstimatedTermCondition2 {
                  idDomain
                  description
                 }`
              : ''
          }
        }
      }
    }
  `;

    const [
      loadForeignTradeSuppliersData,
      {
        loading: foreignTradeSuppliersLoading,
        data: foreignTradeSuppliersData,
      },
    ] = useLazyQuery(listSatForeignTradeSuppliersQuery, {
      variables: {
        data: {
          pagination: {
            _page: foreignTradeSuppliersState.lazyParams.page + 1,
            _limit: foreignTradeSuppliersState.lazyParams.rows,
            _sortOrder: 'ASC',
          },
          idSat: sat.idSat,
        },
      },
      onCompleted: response => {
        if (response.listSatForeignTradeSuppliersBySatId) {
          const foreignTradeSupplierId = urlParam ? parseInt(urlParam, 10) : 0;
          const foreignTradeSuppliers =
            response.listSatForeignTradeSuppliersBySatId;
          const index = foreignTradeSuppliers.data?.findIndex(
            (foreignTrade: any) =>
              foreignTrade.idSatForeignTradeSupplier === foreignTradeSupplierId,
          );
          foreignTradeSuppliersDispatch({
            type: ForeignTradeSupplierReducerActionKind.SET_FOREIGN_TRADE_SUPPLIERS,
            payload: {
              foreignTradeSuppliers:
                response.listSatForeignTradeSuppliersBySatId,
              tabActiveIndex:
                index >= 0
                  ? index + 1
                  : foreignTradeSuppliersState.tabActiveIndex,
            },
          });
        }
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Foreign Trade Suppliers',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    function onPage(e: ITabViewAsyncPageParams) {
      foreignTradeSuppliersDispatch({
        type: ForeignTradeSupplierReducerActionKind.SET_LAZY_PARAMS,
        payload: {
          lazyParams: { ...foreignTradeSuppliersState.lazyParams, ...e },
        },
      });
    }

    const [
      loadFinancialPurchaseFileTypesData,
      {
        loading: financialPurchaseFileTypesLoading,
        data: financialPurchaseFileTypesData,
      },
    ] = useLazyQuery(listDomainsByGroupIdQuery, {
      variables: {
        pagination: {
          _limit: 0,
          _page: 1,
        },
        id: DomainGroup.FINANCIAL_PURCHASE_TYPE_FILES,
        isActive: true,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Financial Purchase File Types',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    const [
      loadFinancialSellerFileTypesData,
      {
        loading: financialSellerFileTypesLoading,
        data: financialSellerFileTypesData,
      },
    ] = useLazyQuery(listDomainsByGroupIdQuery, {
      variables: {
        pagination: {
          _limit: 0,
          _page: 1,
        },
        id: DomainGroup.FINANCIAL_SELLER_TYPE_FILES,
        isActive: true,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Financial Seller File Types',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    const [
      loadFinancialForeignTradeFileTypesData,
      {
        loading: financialForeignTradeFileTypesLoading,
        data: financialForeignTradeFileTypesData,
      },
    ] = useLazyQuery(listDomainById, {
      variables: {
        idDomain: ForeignTradeTypeFiles.CI_PL_SIGNED,
      },
      onError: errorData => {
        toastRef.current?.show({
          severity: 'error',
          summary: 'Error while getting Financial Foreign Trade File Types',
          detail: errorData.message,
          life: ToastLife.ERROR,
        });
      },
    });

    const handleRefreshFinancial = useCallback(async () => {
      // Se os Lazy Params nao tiverem sido modificados, eh necessario chamar
      // o metodo para carregar os suppliers novamente explicitamente
      if (
        isEqual(
          foreignTradeSuppliersState.lazyParams,
          pagination.initialLazyParams,
        )
      ) {
        // Necessario definir suppliers como undefined para garantir a
        // atualizacao dos dados em tela
        foreignTradeSuppliersDispatch({
          type: ForeignTradeSupplierReducerActionKind.SET_FOREIGN_TRADE_SUPPLIERS,
          payload: {
            foreignTradeSuppliers: undefined,
            tabActiveIndex: foreignTradeSuppliersState.tabActiveIndex,
          },
        });
        await loadForeignTradeSuppliersData();
      } else {
        // Caso Lazy Params tenha sido modificado, o ato de restaurar os valores
        // ja chama o useMutation novamente para carregar os valores de Foreign Trade Suppliers
        foreignTradeSuppliersDispatch({
          type: ForeignTradeSupplierReducerActionKind.RESET_STATE,
        });
      }
    }, [
      foreignTradeSuppliersState.lazyParams,
      foreignTradeSuppliersState.tabActiveIndex,
      loadForeignTradeSuppliersData,
    ]);

    const handleSubmitFinancial = useCallback(
      async (data?: { data?: FinancialFormData[] }) => {
        const response: UpdateSatForeignTradeSuppliersResponse = {
          errors: [],
          warnings: [],
        };

        if (!data) return response;

        const formItemsToUpdate: FinancialFormData[] = [];

        // Apenas registros que foram modificados deverao ser enviados para
        // serem atualizados
        data.data?.forEach(formForeignTradeSupplier => {
          const currentValue =
            foreignTradeSuppliersState.foreignTradeSuppliers?.data.find(
              current =>
                current.idSatForeignTradeSupplier ===
                formForeignTradeSupplier.idSatForeignTradeSupplier,
            );

          if (
            currentValue &&
            !objectsAreEqual(formForeignTradeSupplier, currentValue)
          ) {
            formItemsToUpdate.push(formForeignTradeSupplier);
          }
        });

        // Se nao houver nada para ser atualizado, aborta execucao mas recarrega a pagina
        if (!formItemsToUpdate.length) {
          handleRefreshFinancial();
          return response;
        }

        try {
          const formResponse = await updateSatForeignTradeSuppliersMutation({
            variables: {
              data: formItemsToUpdate,
            },
          });

          response.warnings.push(
            ...formResponse.data.updateSatForeignTradeSuppliers.warnings,
          );

          handleRefreshFinancial();
          return response;
        } catch (error) {
          response.errors.push(error.message);

          return response;
        }
      },
      [
        foreignTradeSuppliersState.foreignTradeSuppliers?.data,
        handleRefreshFinancial,
        updateSatForeignTradeSuppliersMutation,
      ],
    );

    useImperativeHandle(ref, () => ({
      validateForm: async () => {
        const formData = formRef.current?.getData();

        if (!formData) return false;

        try {
          formRef.current?.setErrors({});

          return true;
        } catch (error) {
          setSelectedMenuItem(pageMenuItemKey);

          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          const firstError = error.inner[0];

          const tabWithError = getIndexPositionFromErrorPath(firstError.path);

          if (tabWithError !== undefined) {
            foreignTradeSuppliersDispatch({
              type: ForeignTradeSupplierReducerActionKind.SET_ACTIVE_TAB_INDEX,
              payload: { tabActiveIndex: tabWithError },
            });
          }

          const inputWithError = formRef.current?.getFieldRef(firstError.path);

          if (inputWithError.focus) {
            inputWithError.focus();
          } else if (inputWithError.inputRef?.current?.focus) {
            inputWithError.inputRef?.current?.focus();
          }

          toastRef.current?.show({
            severity: 'warn',
            summary: 'Financial tab',
            detail: 'Please fill all the required fields',
            life: ToastLife.WARN,
          });

          throw new ValidationError(error);
        }
      },
      submitForm: async () => {
        const formData = formRef.current?.getData();

        return handleSubmitFinancial(formData);
      },
      refreshForm: () => {
        if (foreignTradeSuppliersData) handleRefreshFinancial();
      },
    }));

    useEffect(() => {
      if (!foreignTradeSuppliersData && selected) {
        loadForeignTradeSuppliersData();
        loadFinancialPurchaseFileTypesData();
        loadFinancialSellerFileTypesData();
        loadFinancialForeignTradeFileTypesData();
      }
    }, [
      foreignTradeSuppliersData,
      loadFinancialPurchaseFileTypesData,
      loadFinancialSellerFileTypesData,
      loadForeignTradeSuppliersData,
      loadFinancialForeignTradeFileTypesData,
      selected,
    ]);

    return (
      <PageTabContainer selected={selected}>
        {((foreignTradeSuppliersLoading &&
          !!foreignTradeSuppliersState.lazyParams.page) ||
          !foreignTradeSuppliersLoading) &&
          foreignTradeSuppliersState.foreignTradeSuppliers && (
            <Form
              ref={formRef}
              onSubmit={handleSubmitFinancial}
              initialData={foreignTradeSuppliersState.foreignTradeSuppliers}
            >
              <TabViewAsync
                scrollable
                first={foreignTradeSuppliersState.lazyParams.first}
                rows={foreignTradeSuppliersState.lazyParams.rows}
                totalRecords={
                  foreignTradeSuppliersState.foreignTradeSuppliers?.items ?? 0
                }
                loading={foreignTradeSuppliersLoading}
                page={foreignTradeSuppliersState.lazyParams.page}
                itemsToRenderLength={
                  foreignTradeSuppliersState.foreignTradeSuppliers?.data
                    .length + 1 || 0
                }
                onPage={e => onPage(e)}
                activeIndex={foreignTradeSuppliersState.tabActiveIndex}
                onTabChange={e =>
                  foreignTradeSuppliersDispatch({
                    type: ForeignTradeSupplierReducerActionKind.SET_ACTIVE_TAB_INDEX,
                    payload: { tabActiveIndex: e.index },
                  })
                }
                renderActiveOnly={false}
              >
                <TabPanel
                  key="totalFinancial"
                  header="Total Financial"
                  headerClassName={`${!userCanSeeTotalFinancial && 'hidden'}`}
                >
                  {userCanSeeTotalFinancial && (
                    <Total
                      sat={sat}
                      selected={foreignTradeSuppliersState.tabActiveIndex === 0}
                      commonWithSatFieldsPermissions={
                        commonWithSatFieldsPermissions
                      }
                      fieldsPermissions={fieldsPermissions}
                      userCanObjects={userCanObjects}
                      purchaseOtherExpensesFieldsPermissions={
                        purchaseOtherExpensesFieldsPermissions
                      }
                      purchaseOtherExpensesTotalFieldsPermissions={
                        purchaseOtherExpensesTotalFieldsPermissions
                      }
                      purchaseAdvancePaidFieldsPermissions={
                        advancePaidFieldsPermissions
                      }
                      purchaseBalancePaidFieldsPermissions={
                        balancePaidFieldsPermissions
                      }
                      purchaseTotalPaidFieldsPermissions={
                        totalPaidFieldsPermissions
                      }
                      userPermissions={userPermissions}
                      sellerOtherExpensesFieldsPermissions={
                        sellerOtherExpensesFieldsPermissions
                      }
                      sellerOtherExpensesTotalFieldsPermissions={
                        sellerOtherExpensesTotalFieldsPermissions
                      }
                      sellerAdvanceReceivedFieldsPermissions={
                        advanceReceivedFieldsPermissions
                      }
                      sellerBalanceReceivedFieldsPermissions={
                        balanceReceivedFieldsPermissions
                      }
                      sellerTotalReceivedFieldsPermissions={
                        totalReceivedFieldsPermissions
                      }
                      resultsFieldsPermissions={resultsFieldsPermissions}
                    />
                  )}
                </TabPanel>
                {foreignTradeSuppliersState.foreignTradeSuppliers?.data.map(
                  (item: SatForeignTradeSupplier, index: number) => {
                    const tabIndex = index + 1;

                    return (
                      <TabPanel
                        key={item.idSatForeignTradeSupplier}
                        header={
                          item.satForeignTradeSupplierNumber ??
                          item.idSatForeignTradeSupplier
                        }
                      >
                        <Content
                          itemIndex={index}
                          satForeignTradeSupplier={item}
                          sat={sat}
                          fieldsPermissions={fieldsPermissions}
                          userCanObjects={userCanObjects}
                          commonWithSatFieldsPermissions={
                            commonWithSatFieldsPermissions
                          }
                          selected={
                            foreignTradeSuppliersState.tabActiveIndex ===
                            tabIndex
                          }
                          purchaseOtherExpensesFieldsPermissions={
                            purchaseOtherExpensesFieldsPermissions
                          }
                          sellerOtherExpensesFieldsPermissions={
                            sellerOtherExpensesFieldsPermissions
                          }
                          advancePaidFieldsPermissions={
                            advancePaidFieldsPermissions
                          }
                          advancePaidPermissions={advancePaidPermissions}
                          advanceReceivedFieldsPermissions={
                            advanceReceivedFieldsPermissions
                          }
                          advanceReceivedPermissions={
                            advanceReceivedPermissions
                          }
                          balancePaidFieldsPermissions={
                            balancePaidFieldsPermissions
                          }
                          balancePaidPermissions={balancePaidPermissions}
                          balanceReceivedFieldsPermissions={
                            balanceReceivedFieldsPermissions
                          }
                          balanceReceivedPermissions={
                            balanceReceivedPermissions
                          }
                          totalPaidFieldsPermissions={
                            totalPaidFieldsPermissions
                          }
                          totalReceivedFieldsPermissions={
                            totalReceivedFieldsPermissions
                          }
                          purchaseOtherExpensesTotalFieldsPermissions={
                            purchaseOtherExpensesTotalFieldsPermissions
                          }
                          sellerOtherExpensesTotalFieldsPermissions={
                            sellerOtherExpensesTotalFieldsPermissions
                          }
                          financialPurchaseFileTypesData={
                            financialPurchaseFileTypesData?.listDomainsByGroupId
                              .data
                          }
                          financialPurchaseFileTypesLoading={
                            financialPurchaseFileTypesLoading
                          }
                          financialSellerFileTypesData={
                            financialSellerFileTypesData?.listDomainsByGroupId
                              .data
                          }
                          financialSellerFileTypesLoading={
                            financialSellerFileTypesLoading
                          }
                          financialForeignTradeFileTypesData={[
                            financialForeignTradeFileTypesData?.listDomainById,
                          ]}
                          financialForeignTradeFileTypesLoading={
                            financialForeignTradeFileTypesLoading
                          }
                          purchaseFilesPermissions={purchaseFilesPermissions}
                          sellerFilesPermissions={sellerFilesPermissions}
                          usersPurchaseFieldsPermissions={
                            usersPurchaseFieldsPermissions
                          }
                          usersSellerFieldsPermissions={
                            usersSellerFieldsPermissions
                          }
                          resultsFieldsPermissions={resultsFieldsPermissions}
                          apportionmentRef={apportionmentRef}
                          formRef={formRef}
                        />
                      </TabPanel>
                    );
                  },
                )}
              </TabViewAsync>
            </Form>
          )}

        {foreignTradeSuppliersLoading &&
          !foreignTradeSuppliersState.lazyParams.page && (
            <>
              <Skeleton height="3rem" className="mb-3" />

              <Skeleton width="10rem" height="2.8rem" />

              {[...Array(4)].map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="flex mt-4" key={i}>
                  <Skeleton className="mr-3" height="3rem" width="16%" />
                  <Skeleton className="mr-3" height="3rem" width="16%" />
                  <Skeleton className="mr-3" height="3rem" width="16%" />
                  <Skeleton className="mr-3" height="3rem" width="16%" />
                  <Skeleton className="mr-3" height="3rem" width="16%" />
                </div>
              ))}
            </>
          )}

        {!foreignTradeSuppliersLoading &&
          !foreignTradeSuppliersState.foreignTradeSuppliers?.items && (
            <Empty message="There is no Financial data to show" />
          )}
      </PageTabContainer>
    );
  },
);

export default Financial;
