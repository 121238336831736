import { FormHandles } from '@unform/core';
import { confirmDialog } from 'primereact/confirmdialog';
import React, { RefObject, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import FormAsyncSelect from '../../../../../../components/FormAsyncSelect';
import FormInput from '../../../../../../components/FormInput';
import FormInputNumber from '../../../../../../components/FormInputNumber';
import FormTitle from '../../../../../../components/FormTitle';
import { DomainGroup } from '../../../../../../shared/enums/domainGroup';
import { ForeignTradeStatus } from '../../../../../../shared/enums/domains';
import { asyncSelectLoadDomains } from '../../../../../../shared/querys/domain';
import { parseToLocaleStringDate } from '../../../../../../utils/convertDate';
import { getUTC } from '../../../../../../utils/dateUtil';
import {
  ForeignTradeFieldsCommonWithSatFieldsPermissions,
  ISat,
} from '../../../interfaces';
import {
  Domain,
  ForeignTradeFieldsPermissions,
  SatForeignTrade,
} from '../../interfaces';
import {
  satForeignTradeRoles,
  satsRoles,
} from '../../../../../../shared/roles/sat';
import CheckboxMenu from '../../../../../../components/CheckboxMenu';
import { getSatReportsQuery } from '../../../queries';
import MutationResponseSummary, {
  IMutationResponse,
  IMutationResponseSummaryRef,
} from '../../../../../../components/MutationResponseSummary';
import userHasPermission from '../../../../../../utils/userHasPermission';
import { IUserFieldsAndPermissionsResponse } from '../../../../../../utils/getUserFieldsAndPermissionsByEntity';
import { Container } from './styles';
import { useRefHook } from '../../../../../../hooks/useRefHook';
import ToastLife from '../../../../../../shared/enums/toastLife';
import ReadOnlyInput from '../../../../../../components/ReadOnlyInput';
import AdvancePaymentRequestMenu from './AdvancePaymentRequest';
import Button from '../../../../../../components/Button';
import { ISatForeignTradeRef } from '../..';
import BalancePaymentRequestMenu from './BalancePaymentRequest';

interface IScheduleProps {
  commonWithSatFieldsPermissions: ForeignTradeFieldsCommonWithSatFieldsPermissions;
  sat: ISat;
  fieldsPermissions: ForeignTradeFieldsPermissions;
  foreignTrade: SatForeignTrade;
  shipmentIsCanceled: boolean;
  formRef: RefObject<FormHandles>;
  userPermissionsSat: IUserFieldsAndPermissionsResponse;
  foreignTradeRef: React.RefObject<ISatForeignTradeRef>;
}

const Schedule: React.FC<IScheduleProps> = ({
  commonWithSatFieldsPermissions,
  sat,
  fieldsPermissions,
  foreignTrade,
  shipmentIsCanceled,
  formRef,
  userPermissionsSat,
  foreignTradeRef,
}) => {
  const { toastRef } = useRefHook();

  const {
    idPermissionExportDdgip,
    idPermissionExportCiPlusPl,
    idPermissionExportShippingInstruction,
    idPermissionExportSellerPi,
    idPermissionExportSellerPiSimplified,
    idPermissionExportCotPI,
    idPermissionExportOd,
    idPermissionExportContract,
  } = satsRoles.permissions;

  const {
    idPermissionAdvancePaymentRequest,
    idPermissionBalancePaymentRequest,
  } = satForeignTradeRoles.permissions;

  const downloadFilesSummaryRef = useRef<IMutationResponseSummaryRef>(null);

  const [downloadFilesResponse, setDownloadFilesResponse] = useState<
    IMutationResponse[]
  >([]);

  const [
    isVisibleAdvancePaymentRequestMenu,
    setIsVisibleAdvancePaymentRequestMenu,
  ] = useState(false);

  const [
    isVisibleBalancePaymentRequestMenu,
    setIsVisibleBalancePaymentRequestMenu,
  ] = useState(false);

  const [downloadSatReports, { loading: downloadSatReportsInProgress }] =
    useLazyQuery(getSatReportsQuery);

  const downloadOptions = useMemo(() => {
    const items = [];
    const docsPi = [];
    const docsCi = [];

    if (
      userHasPermission(
        idPermissionExportDdgip,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsCi.push({
        key: 'ddgip',
        label: 'Dados Para DI',
      });
    }

    if (
      userHasPermission(
        idPermissionExportCiPlusPl,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsCi.push({
        key: 'ciPl',
        label: 'CI + PL',
      });
    }

    if (
      userHasPermission(
        idPermissionExportShippingInstruction,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsCi.push({
        key: 'shippingInstruction',
        label: 'Shipping Instruction',
      });
    }

    if (
      userHasPermission(
        idPermissionExportSellerPi,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsPi.push({
        key: 'sunPi',
        label: 'SUN PI',
      });
    }

    if (
      userHasPermission(
        idPermissionExportSellerPiSimplified,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsPi.push({
        key: 'sunPiSimplified',
        label: 'SUN PI SIMPLIFIED',
      });
    }

    if (
      userHasPermission(
        idPermissionExportCotPI,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsPi.push({
        key: 'cotPi',
        label: 'COT PI',
      });
    }

    if (
      userHasPermission(
        idPermissionExportOd,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsPi.push({
        key: 'od',
        label: 'OD',
      });
    }

    if (
      userHasPermission(
        idPermissionExportContract,
        userPermissionsSat.userPermissions,
      )
    ) {
      docsPi.push({
        key: 'contract',
        label: 'Contract',
      });
    }

    if (docsCi.length) {
      items.push({
        key: 'docsCi',
        label: 'Docs CI',
        children: docsCi,
      });
    }

    if (docsPi.length) {
      items.push({
        key: 'docsPi',
        label: 'Docs PI',
        children: docsPi,
      });
    }

    return items;
  }, [
    idPermissionExportDdgip,
    userPermissionsSat.userPermissions,
    idPermissionExportCiPlusPl,
    idPermissionExportShippingInstruction,
    idPermissionExportSellerPi,
    idPermissionExportSellerPiSimplified,
    idPermissionExportCotPI,
    idPermissionExportOd,
    idPermissionExportContract,
  ]);

  function handleChangeImportStatus(e: Domain) {
    if (e.idDomain === ForeignTradeStatus.CANCELED) {
      confirmDialog({
        message:
          'This option will cancel the shipment. Do you want to proceed?',
        header: `Canceling shipment ${foreignTrade.satForeignTradeNumber}`,
        icon: 'pi pi-question-circle',
        reject: () => {
          formRef.current?.setFieldValue(
            'idImportStatus',
            foreignTrade.idImportStatus2,
          );
        },
      });
    }
  }

  async function handleDownloadFiles(itemsToDownload: string[]) {
    try {
      const response = await downloadSatReports({
        variables: {
          generateSatBulkDownloadsInput: {
            idSat: sat.idSat,
            itemsToDownload,
            idSatForeignTrade: foreignTrade.idSatForeignTrade,
          },
        },
      });

      if (response.data.generateSatBulkDownloads) {
        const { downloadUrl, summary } = response.data.generateSatBulkDownloads;
        if (downloadUrl) {
          window.open(downloadUrl, '_blank');
        }

        if (summary.length) {
          setDownloadFilesResponse(
            summary.map((item: any) => {
              return {
                errors: [item.error],
                moduleChanged: item.reportName,
              };
            }),
          );

          downloadFilesSummaryRef.current?.toggleDisplaySummary();
        }
      }
    } catch (error) {
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error while downloading Sat Reports',
        detail: error.message,
        life: ToastLife.ERROR,
      });
    }
  }

  const shouldRenderAdvancePaymentRequestButton = useMemo(() => {
    return (
      !!sat.paymentTermAdvance &&
      sat.paymentTermAdvance > 0 &&
      userHasPermission(
        idPermissionAdvancePaymentRequest,
        userPermissionsSat.userPermissions,
      )
    );
  }, [
    sat.paymentTermAdvance,
    idPermissionAdvancePaymentRequest,
    userPermissionsSat.userPermissions,
  ]);

  const shouldRenderBalancePaymentRequestButton = useMemo(() => {
    return (
      !!sat.paymentTermBalance &&
      sat.paymentTermBalance > 0 &&
      userHasPermission(
        idPermissionBalancePaymentRequest,
        userPermissionsSat.userPermissions,
      )
    );
  }, [
    sat.paymentTermBalance,
    idPermissionBalancePaymentRequest,
    userPermissionsSat.userPermissions,
  ]);

  return (
    <Container>
      <div className="form-group">
        <MutationResponseSummary
          ref={downloadFilesSummaryRef}
          header="Download Files Summary"
          data={downloadFilesResponse}
        />

        {isVisibleAdvancePaymentRequestMenu && (
          <AdvancePaymentRequestMenu
            sat={sat}
            foreignTrade={foreignTrade}
            isVisibleAdvancePaymentRequestMenu={
              isVisibleAdvancePaymentRequestMenu
            }
            setIsVisibleAdvancePaymentRequestMenu={
              setIsVisibleAdvancePaymentRequestMenu
            }
            foreignTradeRef={foreignTradeRef}
          />
        )}

        {isVisibleBalancePaymentRequestMenu && (
          <BalancePaymentRequestMenu
            sat={sat}
            foreignTrade={foreignTrade}
            isVisibleBalancePaymentRequestMenu={
              isVisibleBalancePaymentRequestMenu
            }
            setIsVisibleBalancePaymentRequestMenu={
              setIsVisibleBalancePaymentRequestMenu
            }
            foreignTradeRef={foreignTradeRef}
          />
        )}

        <FormTitle
          className="col-12 md:col-12 mt-5 content-header"
          name="Schedule"
        >
          <div style={{ display: 'flex', gap: '12px' }}>
            {shouldRenderAdvancePaymentRequestButton && (
              <Button
                className="secondaryButton"
                label="Advance Payment Request"
                onClick={() => setIsVisibleAdvancePaymentRequestMenu(true)}
                disabled={!!foreignTrade.advancePaymentRequestDate}
              />
            )}
            {shouldRenderBalancePaymentRequestButton && (
              <Button
                className="secondaryButton"
                label="Balance Payment Request"
                onClick={() => setIsVisibleBalancePaymentRequestMenu(true)}
                disabled={!!foreignTrade.balancePaymentRequestDate}
              />
            )}
            {!!downloadOptions.length && (
              <CheckboxMenu
                className="secondaryButton"
                label="Download Area"
                options={downloadOptions}
                filter
                onGetSelectedItems={e => handleDownloadFiles(e)}
                getSelectedOptionsLoading={downloadSatReportsInProgress}
              />
            )}
          </div>
        </FormTitle>

        <FormInputNumber
          name="idSatForeignTrade"
          label="ID Foreign Trade"
          hidden
        />

        <div className="flex flex-wrap">
          {commonWithSatFieldsPermissions.confirmOrderComercial.view && (
            <ReadOnlyInput
              className="col-3"
              label="Start Date"
              value={
                sat.confirmOrderComercial
                  ? parseToLocaleStringDate(getUTC(sat.confirmOrderComercial))
                  : ''
              }
            />
          )}

          {fieldsPermissions.idImportStatus.view && (
            <FormAsyncSelect
              className="col-3"
              name="idImportStatus"
              label="Import Status"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              additional={{
                id: DomainGroup.FOREIGN_TRADE_STATUS,
                orderByDescription: true,
              }}
              noOptionsMessage={() => 'No conditions found'}
              initialValue={foreignTrade.idImportStatus2}
              onValueChange={e => handleChangeImportStatus(e)}
              readOnly={
                shipmentIsCanceled || !fieldsPermissions.idImportStatus.edit
              }
            />
          )}

          {fieldsPermissions.idExportStatus.view && (
            <FormAsyncSelect
              className="col-3"
              name="idExportStatus"
              label="Export Status"
              loadOptions={asyncSelectLoadDomains}
              getOptionLabel={option => option.description}
              getOptionValue={option => option.idDomain}
              additional={{
                id: DomainGroup.EXPORT_STATUS,
                orderByDescription: true,
              }}
              noOptionsMessage={() => 'No status found'}
              initialValue={foreignTrade.idExportStatus2}
              readOnly={
                shipmentIsCanceled || !fieldsPermissions.idExportStatus.edit
              }
            />
          )}
        </div>

        <div className="flex flex-wrap">
          {fieldsPermissions.estimatedInspectionDate.view && (
            <FormInput
              name="estimatedInspectionDate"
              className="col-3"
              label="Est. Inspection Date"
              type="date"
              required
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.estimatedInspectionDate.edit
              }
            />
          )}

          {fieldsPermissions.estimatedShipmentDate.view && (
            <FormInput
              className="col-3"
              name="estimatedShipmentDate"
              label="Est. Shipment Date"
              type="date"
              required
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.estimatedShipmentDate.edit
              }
            />
          )}

          {fieldsPermissions.estimatedArrivalDate.view && (
            <FormInput
              className="col-3"
              name="estimatedArrivalDate"
              label="Est. Arrival Date"
              type="date"
              required
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.estimatedArrivalDate.edit
              }
            />
          )}

          {fieldsPermissions.estimatedArrivalClientDate.view && (
            <FormInput
              className="col-3"
              name="estimatedArrivalClientDate"
              label="Est. Arrival At Client"
              type="date"
              required
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.estimatedArrivalClientDate.edit
              }
            />
          )}
        </div>

        <div className="flex flex-wrap">
          {fieldsPermissions.realInspectionDate.view && (
            <FormInput
              className="col-3"
              name="realInspectionDate"
              label="Real Inspection Date"
              type="date"
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.realInspectionDate.edit ||
                foreignTrade.noReport
              }
            />
          )}

          {fieldsPermissions.realEtd.view && (
            <FormInput
              className="col-3"
              name="realEtd"
              label="Real ETD"
              type="date"
              required
              readOnly={shipmentIsCanceled || !fieldsPermissions.realEtd.edit}
            />
          )}

          {fieldsPermissions.realEta.view && (
            <FormInput
              className="col-3"
              name="realEta"
              label="Real ETA"
              type="date"
              required
              readOnly={shipmentIsCanceled || !fieldsPermissions.realEta.edit}
            />
          )}

          {fieldsPermissions.realArrivalAtClient.view && (
            <FormInput
              className="col-3"
              name="realArrivalAtClient"
              label="Real Arrival At Client"
              type="date"
              required
              readOnly={
                shipmentIsCanceled ||
                !fieldsPermissions.realArrivalAtClient.edit
              }
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Schedule;
