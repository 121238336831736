import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import {
  Fragment,
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import Loading from '../../../../../components/Loading';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IDomain } from '../../../../../interfaces/IDomain';
import { IStatusLayout } from '../../../../../interfaces/IStatusLayout';
import {
  ArtworkProductInfoForAwOptions,
  RegisterClassificacaoFiscalOptions,
  RegisterClientRegisterOptions,
  RegisterHrpOptions,
  RegisterProductDetailsOptions,
} from '../../../../../shared/enums/processStepStatusOption';
import { StatusLayout } from '../../../../../shared/enums/statusLayout';
import { asyncSelectLoadDomains } from '../../../../../shared/querys/domain';
import { getUTC } from '../../../../../utils/dateUtil';
import { IProcess, ISatProcessResult } from '../interfaces';
import { ListProcessAndSatProcessesBySatForeignTradeIdQuery } from '../queries';
import { Container } from '../styles';
import {
  Process,
  ProcessTitle,
  Step,
  StepTag,
  StepTitle,
  Steps,
  StepsTitle,
} from './styles';
import RegisterProductDetailsUpdateMissingInfoDialog from './RegisterProductDetailsUpdateMissingInfoDialog';
import RegisterRequestClassificacaoFiscalDialog from './RegisterRequestClassificacaoFiscalDialog';
import { ISat } from '../../interfaces';
import {
  processArtworkProductInfoForAwDoneQuery,
  processRegisterClientRegisterNotRequiredQuery,
  processRegisterClientRegisterRequestQuery,
  processRegisterHrpNotRequiredQuery,
  processRegisterProductDetailsDoneQuery,
} from './queries';
import { useAuth } from '../../../../../hooks/useAuth';
import getUserFieldsAndPermissionsByEntity from '../../../../../utils/getUserFieldsAndPermissionsByEntity';
import { satStatusRoles } from '../../../../../shared/roles/satStatusRoles';
import userHasPermission from '../../../../../utils/userHasPermission';
import { ProcessStepStatus, ProcessType, SupplierPaymentStep } from './enum';
import ArtworkProductInfoForAwUpdateMissingInfoDialog from './ArtworkProductInfoForAwUpdateMissingInfoDialog';
import StatusHandlerLog from '../StatusHandlerLog';

export interface IStatusContentRef {
  refreshData(): Promise<ApolloQueryResult<void>>;
}

interface IStatusContentProps {
  ref: Ref<IStatusContentRef>;
  sat: ISat;
  idSatForeignTrade: number;
}

const StatusContent: React.FC<IStatusContentProps> = forwardRef(
  ({ sat, idSatForeignTrade }, ref) => {
    const { showError, showSuccess, showWarn } = useRefHook();

    const [pageLoading, setPageLoading] = useState(false);
    const [processes, setProcesses] = useState<IProcess[]>();
    const [idSatProcessResultToEdit, setIdSatProcessResultToEdit] = useState<
      number | null
    >(null);

    const { roles } = useAuth();

    const userPermissionsSatStatus = useMemo(() => {
      return getUserFieldsAndPermissionsByEntity(
        roles.rolesUser,
        satStatusRoles.idEntity,
      );
    }, [roles.rolesUser]);

    const permissions = useMemo(() => {
      return {
        registerProductDetailsUpdateMissingInfo: userHasPermission(
          satStatusRoles.permissions.registerProductDetailsUpdateMissingInfo,
          userPermissionsSatStatus.userPermissions,
        ),
        registerProductDetailsDone: userHasPermission(
          satStatusRoles.permissions.registerProductDetailsDone,
          userPermissionsSatStatus.userPermissions,
        ),
        registerClassificacaoFiscalRequestClassificacaoFiscal:
          userHasPermission(
            satStatusRoles.permissions
              .registerClassificacaoFiscalRequestClassificacaoFiscal,
            userPermissionsSatStatus.userPermissions,
          ),
        registerHRPCheck: userHasPermission(
          satStatusRoles.permissions.registerHRPCheck,
          userPermissionsSatStatus.userPermissions,
        ),
        registerHRPNotRequired: userHasPermission(
          satStatusRoles.permissions.registerHRPNotRequired,
          userPermissionsSatStatus.userPermissions,
        ),
        registerClientRegisterRequest: userHasPermission(
          satStatusRoles.permissions.registerClientRegisterRequest,
          userPermissionsSatStatus.userPermissions,
        ),
        registerClientRegisterNotRequired: userHasPermission(
          satStatusRoles.permissions.registerClientRegisterNotRequired,
          userPermissionsSatStatus.userPermissions,
        ),
        artworkProductInfoForAwUpdateMissingInfo: userHasPermission(
          satStatusRoles.permissions.artworkProductInfoForAwUpdateMissingInfo,
          userPermissionsSatStatus.userPermissions,
        ),
        artworkProductInfoForAwDone: userHasPermission(
          satStatusRoles.permissions.artworkProductInfoForAwDone,
          userPermissionsSatStatus.userPermissions,
        ),
      };
    }, [userPermissionsSatStatus.userPermissions]);

    const [processRegisterProductDetailsDone] = useMutation(
      processRegisterProductDetailsDoneQuery,
    );

    const [processRegisterHrpNotRequired] = useMutation(
      processRegisterHrpNotRequiredQuery,
    );

    const [processRegisterClientRegisterRequest] = useMutation(
      processRegisterClientRegisterRequestQuery,
    );

    const [processRegisterClientRegisterNotRequired] = useMutation(
      processRegisterClientRegisterNotRequiredQuery,
    );

    const [processArtworkProductInfoForAwDone] = useMutation(
      processArtworkProductInfoForAwDoneQuery,
    );

    const [displayWarningMap, setDisplayWarningMap] = useState<{
      [key: number]: boolean;
    }>({});

    const [
      displayRegisterProductDetailUpdateMissingInfoDialog,
      setDisplayRegisterProductDetailUpdateMissingInfoDialog,
    ] = useState(false);

    const [
      displayRegisterRequestClassificacaoFiscalDialog,
      setDisplayRegisterRequestClassificacaoFiscalDialog,
    ] = useState(false);

    const [
      displayArtworkProductInfoForAwUpdateMissingInfoDialog,
      setDisplayArtworkProductInfoForAwUpdateMissingInfoDialog,
    ] = useState(false);

    const { loading: processesLoading, refetch: refetchStatusProcessData } =
      useQuery(ListProcessAndSatProcessesBySatForeignTradeIdQuery, {
        variables: {
          idSatForeignTrade,
        },
        // Garante que o loading seja exibido enquanto a query estiver em
        // andamento
        notifyOnNetworkStatusChange: true,
        onCompleted: response => {
          if (response.listProcessAndSatProcessesBySatForeignTradeId) {
            setProcesses(
              response.listProcessAndSatProcessesBySatForeignTradeId,
            );
          }
        },
        onError: errorData => {
          showError({
            summary: 'Error while getting Status',
            detail: errorData.message,
          });
        },
      });

    useImperativeHandle(ref, () => ({
      refreshData: async () => {
        return refetchStatusProcessData();
      },
    }));

    const handleUpdateProductDetailsDone = useCallback(
      async (idSatProcessResult: number) => {
        setPageLoading(true);
        try {
          const result = await processRegisterProductDetailsDone({
            variables: {
              idSatProcessResult,
            },
          });

          await refetchStatusProcessData();

          if (result) {
            showSuccess({ summary: 'Product Details Done' });
          }
        } catch (error) {
          showError({
            summary: 'Error while updating Product Details Done',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
      [
        refetchStatusProcessData,
        processRegisterProductDetailsDone,
        showError,
        showSuccess,
      ],
    );

    const handleUpdateHrpNotRequired = useCallback(
      async (idSatProcessResult: number) => {
        setPageLoading(true);
        try {
          const result = await processRegisterHrpNotRequired({
            variables: { idSat: sat.idSat, idSatProcessResult },
          });

          await refetchStatusProcessData();

          if (result) {
            showSuccess({ summary: 'Hrp Not Required' });
          }
        } catch (error) {
          showError({
            summary: 'Error while updating Hrp Not Required',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
      [
        processRegisterHrpNotRequired,
        sat.idSat,
        refetchStatusProcessData,
        showSuccess,
        showError,
      ],
    );

    const handleUpdateClientRegisterRequest = useCallback(
      async (idSatProcessResult: number) => {
        setPageLoading(true);
        try {
          const result = await processRegisterClientRegisterRequest({
            variables: { idSat: sat.idSat, idSatProcessResult },
          });

          await refetchStatusProcessData();

          if (result) {
            showSuccess({ summary: 'Client Register Request' });
          }
        } catch (error) {
          showError({
            summary: 'Error while updating Client Register Request',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
      [
        refetchStatusProcessData,
        processRegisterClientRegisterRequest,
        sat.idSat,
        showError,
        showSuccess,
      ],
    );

    const handleUpdateClientRegisterNotRequired = useCallback(
      async (idSatProcessResult: number) => {
        setPageLoading(true);
        try {
          const result = await processRegisterClientRegisterNotRequired({
            variables: { idSatProcessResult },
          });

          await refetchStatusProcessData();

          if (result) {
            showSuccess({ summary: 'Client Register Request' });
          }
        } catch (error) {
          showError({
            summary: 'Error while updating Client Register Request',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
      [
        refetchStatusProcessData,
        processRegisterClientRegisterNotRequired,
        showError,
        showSuccess,
      ],
    );

    const handleUpdateArtworkProductInfoForAwDone = useCallback(
      async (idSatProcessResult: number) => {
        setPageLoading(true);
        try {
          const result = await processArtworkProductInfoForAwDone({
            variables: {
              idSatProcessResult,
            },
          });

          await refetchStatusProcessData();

          if (result) {
            showSuccess({ summary: 'Product Info For AW Done' });
          }
        } catch (error) {
          showError({
            summary: 'Error while updating Product Info For AW Done',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
      [
        refetchStatusProcessData,
        processArtworkProductInfoForAwDone,
        showError,
        showSuccess,
      ],
    );

    function onTagClick(hasWarning: boolean, idSatProcessResult: number) {
      if (hasWarning) {
        setDisplayWarningMap(prevMap => ({
          ...prevMap,
          [idSatProcessResult]: true,
        }));
      }
    }

    function getTagContent(
      statusLayout: IStatusLayout | undefined,
      result: ISatProcessResult,
    ) {
      const DONE_TEXT = 'done';

      return (
        <div className="flex">
          {statusLayout?.idLayout === StatusLayout.SUCCESS && (
            <i className="pi pi-check" />
          )}
          {statusLayout?.idLayout === StatusLayout.WARN && (
            <i className="pi pi-exclamation-triangle" />
          )}
          {result.idStepStatus2?.description.toLowerCase() === DONE_TEXT ? (
            <p>{getUTC(result.done).toLocaleDateString()}</p>
          ) : (
            <p>{result.idStepStatus2?.description}</p>
          )}
        </div>
      );
    }

    function registerProductDetailUpdateMissingInfo() {
      setDisplayRegisterProductDetailUpdateMissingInfoDialog(true);
    }

    const registerProductDetailDone = (idSatProcessResult: number) => {
      handleUpdateProductDetailsDone(idSatProcessResult);
    };

    const registerRequestClassificacaoFiscal = () => {
      setDisplayRegisterRequestClassificacaoFiscalDialog(true);
    };

    const registerHrpNotRequired = (idSatProcessResult: number) => {
      handleUpdateHrpNotRequired(idSatProcessResult);
    };

    const registerClientRegisterRequest = (idSatProcessResult: number) => {
      handleUpdateClientRegisterRequest(idSatProcessResult);
    };

    const registerClientRegisterNotRequired = (idSatProcessResult: number) => {
      handleUpdateClientRegisterNotRequired(idSatProcessResult);
    };

    const executeStatusAction = (
      status: IDomain,
      idSatProcessResult: number,
    ) => {
      const idStatus = status.idDomain;
      if (
        idStatus ===
          RegisterProductDetailsOptions.CHECKING_UPDATE_MISSING_INFO &&
        permissions.registerProductDetailsUpdateMissingInfo
      ) {
        registerProductDetailUpdateMissingInfo();
      } else if (
        (idStatus === RegisterProductDetailsOptions.CHECKING_DONE ||
          idStatus ===
            RegisterProductDetailsOptions.UPDATE_MISSING_INFO_DONE) &&
        permissions.registerProductDetailsDone
      ) {
        registerProductDetailDone(idSatProcessResult);
      } else if (
        idStatus ===
          RegisterClassificacaoFiscalOptions.MISSING_REQUEST_CLASSIFICACAO_FISCAL &&
        permissions.registerClassificacaoFiscalRequestClassificacaoFiscal
      ) {
        registerRequestClassificacaoFiscal();
      } else if (
        idStatus === RegisterHrpOptions.MISSING_NOT_REQUIRED &&
        permissions.registerHRPNotRequired
      ) {
        registerHrpNotRequired(idSatProcessResult);
      } else if (
        idStatus === RegisterClientRegisterOptions.MISSING_REQUEST &&
        permissions.registerClientRegisterRequest
      ) {
        registerClientRegisterRequest(idSatProcessResult);
      } else if (
        idStatus === RegisterClientRegisterOptions.MISSING_NOT_REQUIRED &&
        permissions.registerClientRegisterNotRequired
      ) {
        registerClientRegisterNotRequired(idSatProcessResult);
      } else if (
        idStatus ===
          ArtworkProductInfoForAwOptions.CHECKING_UPDATE_MISSING_INFO &&
        permissions.artworkProductInfoForAwUpdateMissingInfo
      ) {
        setDisplayArtworkProductInfoForAwUpdateMissingInfoDialog(true);
      } else if (
        (idStatus === ArtworkProductInfoForAwOptions.CHECKING_DONE ||
          idStatus === ArtworkProductInfoForAwOptions.MISSING_DONE) &&
        permissions.artworkProductInfoForAwDone
      ) {
        handleUpdateArtworkProductInfoForAwDone(idSatProcessResult);
      } else {
        showWarn({
          summary: `${status?.description}`,
          detail: 'Invalid permission for this action!',
        });
      }
    };

    const onChangeSelection = (status: IDomain, idSatProcessResult: number) => {
      setIdSatProcessResultToEdit(idSatProcessResult);

      confirmDialog({
        message: 'Are you sure you want to execute this action?',
        header: 'Update Status Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: async () => {
          executeStatusAction(status, idSatProcessResult);
        },
      });
    };

    return (
      <Container>
        {!processesLoading && !!processes?.length && (
          <StatusHandlerLog idSat={sat.idSat} />
        )}
        {!processesLoading &&
          processes &&
          processes.length > 0 &&
          processes?.map(process => {
            return (
              <Process key={process.idProcess}>
                <ProcessTitle>{process.name}</ProcessTitle>
                {process?.satProcesses?.map(satProcess => {
                  const results = satProcess.satProcessResults;
                  const sftSupplier = satProcess.idSatForeignTradeSupplier2;
                  const sunNumber = sftSupplier?.idSupplier2?.sunNumber;
                  const client = satProcess.idSat2?.idClient2;
                  const rncNumber = satProcess.idSatRnc2?.rncNumber;
                  return (
                    <Fragment key={satProcess.idSatProcess}>
                      <StepsTitle>{sunNumber}</StepsTitle>
                      {process.idProcess === ProcessType.CLIENT_PAYMENT && (
                        <StepsTitle>{client?.name}</StepsTitle>
                      )}
                      {process.idProcess === ProcessType.RNC && (
                        <StepsTitle>{rncNumber}</StepsTitle>
                      )}
                      <Steps>
                        {results.map(result => {
                          const step = result.idProcessStep2;
                          const statusLayout =
                            result?.idStepStatus2?.statusLayout;
                          const stepCssClass = statusLayout?.idLayout
                            ? StatusLayout[
                                statusLayout?.idLayout
                              ]?.toLocaleLowerCase()
                            : '';
                          const hasWarning =
                            (statusLayout?.idLayout === StatusLayout.WARN ||
                              result.idStepStatus2?.idDomain ===
                                ProcessStepStatus.REGISTER_BARCODES_DUPLICITY) &&
                            !!result?.description;
                          return (
                            <Step
                              className={stepCssClass}
                              key={step.idProcessStep}
                            >
                              <StepTitle>{step.name}</StepTitle>
                              <StepTag
                                onClick={() =>
                                  onTagClick(
                                    hasWarning,
                                    result.idSatProcessResult,
                                  )
                                }
                                className={`tag ${
                                  hasWarning ? 'cursor-pointer' : ''
                                }`}
                              >
                                {getTagContent(statusLayout, result)}
                              </StepTag>
                              {step.idProcessStep ===
                                SupplierPaymentStep.BALANCE &&
                                result.description && (
                                  <StepTag className="tag error">
                                    {result.description}
                                  </StepTag>
                                )}
                              {hasWarning && (
                                <Dialog
                                  header="Status Warning Message"
                                  visible={
                                    displayWarningMap[result.idSatProcessResult]
                                  }
                                  style={{ width: '30vw' }}
                                  onHide={() =>
                                    setDisplayWarningMap(prevMap => ({
                                      ...prevMap,
                                      [result.idSatProcessResult]: false,
                                    }))
                                  }
                                >
                                  <p>{result?.description}</p>
                                </Dialog>
                              )}
                              {statusLayout?.idGroupOptions && (
                                <AsyncPaginate
                                  className="status-dropdown"
                                  loadOptions={asyncSelectLoadDomains}
                                  debounceTimeout={1000}
                                  getOptionLabel={(option: any) =>
                                    option.description
                                  }
                                  getOptionValue={(option: any) =>
                                    option.idDomain
                                  }
                                  additional={{
                                    page: 1,
                                    id: statusLayout?.idGroupOptions,
                                  }}
                                  noOptionsMessage={() => 'No option found'}
                                  placeholder=""
                                  onChange={e =>
                                    onChangeSelection(
                                      e,
                                      result.idSatProcessResult,
                                    )
                                  }
                                  menuPosition="fixed"
                                />
                              )}
                            </Step>
                          );
                        })}
                      </Steps>
                    </Fragment>
                  );
                })}
              </Process>
            );
          })}
        {(processesLoading || pageLoading) && <Loading />}
        {idSatProcessResultToEdit && (
          <div>
            <RegisterProductDetailsUpdateMissingInfoDialog
              sat={sat}
              displayDialog={
                displayRegisterProductDetailUpdateMissingInfoDialog
              }
              statusProcessRefech={async () => {
                await refetchStatusProcessData();
              }}
              idSatProcessResult={idSatProcessResultToEdit}
              setDisplayDialog={
                setDisplayRegisterProductDetailUpdateMissingInfoDialog
              }
            />
            <RegisterRequestClassificacaoFiscalDialog
              sat={sat}
              idSatProcessResult={idSatProcessResultToEdit}
              statusProcessRefech={async () => {
                await refetchStatusProcessData();
              }}
              idSatForeignTrade={idSatForeignTrade}
              displayDialog={displayRegisterRequestClassificacaoFiscalDialog}
              setDisplayDialog={
                setDisplayRegisterRequestClassificacaoFiscalDialog
              }
            />
            <ArtworkProductInfoForAwUpdateMissingInfoDialog
              sat={sat}
              displayDialog={
                displayArtworkProductInfoForAwUpdateMissingInfoDialog
              }
              statusProcessRefech={async () => {
                await refetchStatusProcessData();
              }}
              idSatProcessResult={idSatProcessResultToEdit}
              setDisplayDialog={
                setDisplayArtworkProductInfoForAwUpdateMissingInfoDialog
              }
            />
          </div>
        )}
      </Container>
    );
  },
);

export default StatusContent;
