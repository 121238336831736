import React, {
  RefObject,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormHandles } from '@unform/core';
import FormInputNumber from '../../../../../components/FormInputNumber';
import { ISat } from '../../interfaces';
import {
  FinancialFieldsCommonWithSatFieldPermissions,
  FinancialFieldsPermissions,
  SatFinancialAdvancePaidFieldsPermissions,
  SatFinancialAdvancePaidPermissions,
  SatFinancialAdvanceReceivedFieldsPermissions,
  SatFinancialAdvanceReceivedPermissions,
  SatFinancialBalanceFieldsPermissions,
  SatFinancialBalancePermissions,
  SatFinancialFilesPermissions,
  SatFinancialPurchaseOtherExpenseFieldsPermissions,
  SatFinancialPurchaseOtherExpensesTotalFieldsPermissions,
  SatFinancialResultsPermissions,
  SatFinancialSellerOtherExpenseFieldsPermissions,
  SatFinancialSellerOtherExpensesTotalFieldsPermissions,
  SatFinancialTotalFieldsPermissions,
  SatFinancialUsersPurchaseFieldsPermissions,
  SatFinancialUsersSellerFieldsPermissions,
  SatForeignTradeSupplier,
  UserCanObject,
} from '../interfaces';
import AdvancePaid from './AdvancePaid';
import AdvanceReceived from './AdvanceReceived';
import Balance from './Balance';
import EstimatedPaymentPI from './EstimatedPaymentPI';
import EstimatedReceive from './EstimatedReceive';
import Purchase from './Purchase';
import PurchaseCi from './PurchaseCi';
import PurchaseOtherExpenses from './PurchaseOtherExpenses';
import PurchasePi from './PurchasePi';
import Seller from './Seller';
import SellerCi from './SellerCi';
import SellerPi from './SellerPi';

import { IFileType } from '../../../../../components/FilesByTypes/interfaces';
import { DomainGroup } from '../../../../../shared/enums/domainGroup';
import { SatForeignTradePaymentType } from './Balance/interfaces';
import Files from './Files';
import FinancialForeignTradeFiles from './ForeignTradeFiles';
import PurchaseOtherExpensesTotal, {
  IPurchaseOtherExpensesTotalRef,
} from './PurchaseOtherExpensesTotal';
import { foreignTradeSupplierContentReducer } from './reducers';
import Results from './Results';
import SellerOtherExpenses from './SellerOtherExpenses';
import SellerOtherExpensesTotal, {
  ISellerOtherExpensesTotalRef,
} from './SellerOtherExpensesTotal';
import { Row } from '../styles';
import UsersPurchase from './UsersPurchase';
import UsersSeller from './UsersSeller';
import { ISatFinancialApportionmentRef } from '../../FinancialApportionment';
import PurchaseTotalPaid from './PurchaseTotalPaid';
import SellerTotalReceived from './SellerTotalReceived';
import {
  listPurchaseTotalPaidData,
  listSatAttachmentsToFinancialByIdSatQuery,
  listSellerTotalReceivedData,
} from './queries';
import { useRefHook } from '../../../../../hooks/useRefHook';
import { IFinancialFileData } from './interfaces';
import {
  FinancialPurchaseFiles,
  FinancialSellerFiles,
  ForeignTradeStatus,
  ForeignTradeTypeFiles,
} from '../../../../../shared/enums/domains';

interface IContentProps {
  itemIndex: number;
  satForeignTradeSupplier: SatForeignTradeSupplier;
  sat: ISat;
  fieldsPermissions: FinancialFieldsPermissions;
  userCanObjects: UserCanObject;
  commonWithSatFieldsPermissions: FinancialFieldsCommonWithSatFieldPermissions;
  selected: boolean;
  purchaseOtherExpensesFieldsPermissions: SatFinancialPurchaseOtherExpenseFieldsPermissions;
  sellerOtherExpensesFieldsPermissions: SatFinancialSellerOtherExpenseFieldsPermissions;
  advancePaidFieldsPermissions: SatFinancialAdvancePaidFieldsPermissions;
  advancePaidPermissions: SatFinancialAdvancePaidPermissions;
  advanceReceivedFieldsPermissions: SatFinancialAdvanceReceivedFieldsPermissions;
  advanceReceivedPermissions: SatFinancialAdvanceReceivedPermissions;
  balancePaidFieldsPermissions: SatFinancialBalanceFieldsPermissions;
  balancePaidPermissions: SatFinancialBalancePermissions;
  balanceReceivedFieldsPermissions: SatFinancialBalanceFieldsPermissions;
  balanceReceivedPermissions: SatFinancialBalancePermissions;
  totalPaidFieldsPermissions: SatFinancialTotalFieldsPermissions;
  totalReceivedFieldsPermissions: SatFinancialTotalFieldsPermissions;
  purchaseOtherExpensesTotalFieldsPermissions: SatFinancialPurchaseOtherExpensesTotalFieldsPermissions;
  sellerOtherExpensesTotalFieldsPermissions: SatFinancialSellerOtherExpensesTotalFieldsPermissions;
  financialPurchaseFileTypesData: IFileType[];
  financialPurchaseFileTypesLoading: boolean;
  financialSellerFileTypesData: IFileType[];
  financialSellerFileTypesLoading: boolean;
  financialForeignTradeFileTypesData: IFileType[];
  financialForeignTradeFileTypesLoading: boolean;
  purchaseFilesPermissions: SatFinancialFilesPermissions;
  sellerFilesPermissions: SatFinancialFilesPermissions;
  resultsFieldsPermissions: SatFinancialResultsPermissions;
  usersPurchaseFieldsPermissions: SatFinancialUsersPurchaseFieldsPermissions;
  usersSellerFieldsPermissions: SatFinancialUsersSellerFieldsPermissions;
  apportionmentRef: RefObject<ISatFinancialApportionmentRef>;
  formRef: RefObject<FormHandles>;
}

const financialFileTypes = {
  ...FinancialPurchaseFiles,
  ...FinancialSellerFiles,
  CI_PL: ForeignTradeTypeFiles.CI_PL_SIGNED,
};

const Content: React.FC<IContentProps> = ({
  itemIndex,
  satForeignTradeSupplier,
  sat,
  fieldsPermissions,
  userCanObjects,
  commonWithSatFieldsPermissions,
  selected,
  purchaseOtherExpensesFieldsPermissions,
  sellerOtherExpensesFieldsPermissions,
  advancePaidFieldsPermissions,
  advancePaidPermissions,
  advanceReceivedFieldsPermissions,
  advanceReceivedPermissions,
  balancePaidFieldsPermissions,
  balancePaidPermissions,
  balanceReceivedFieldsPermissions,
  balanceReceivedPermissions,
  totalPaidFieldsPermissions,
  totalReceivedFieldsPermissions,
  purchaseOtherExpensesTotalFieldsPermissions,
  sellerOtherExpensesTotalFieldsPermissions,
  financialPurchaseFileTypesData,
  financialPurchaseFileTypesLoading,
  financialSellerFileTypesData,
  financialSellerFileTypesLoading,
  financialForeignTradeFileTypesData,
  financialForeignTradeFileTypesLoading,
  purchaseFilesPermissions,
  sellerFilesPermissions,
  usersPurchaseFieldsPermissions,
  usersSellerFieldsPermissions,
  resultsFieldsPermissions,
  apportionmentRef,
  formRef,
}) => {
  const foreignTradeIsCanceledOrStandBy = [
    ForeignTradeStatus.CANCELED,
    ForeignTradeStatus.STAND_BY,
  ].includes(
    satForeignTradeSupplier.idSatForeignTrade2.idImportStatus2.idDomain,
  );

  function changePermissionBasedOnForeignTradeStatus(
    permission: boolean,
  ): boolean {
    return foreignTradeIsCanceledOrStandBy ? false : permission;
  }

  const foreignTradeBasedUserCanObjects: UserCanObject = {
    ...userCanObjects,
    userCanAddPurchaseOtherExpenses: changePermissionBasedOnForeignTradeStatus(
      userCanObjects.userCanAddPurchaseOtherExpenses,
    ),
    userCanAddSellerOtherExpenses: changePermissionBasedOnForeignTradeStatus(
      userCanObjects.userCanAddSellerOtherExpenses,
    ),
    userCanDeletePurchaseOtherExpenses:
      changePermissionBasedOnForeignTradeStatus(
        userCanObjects.userCanDeletePurchaseOtherExpenses,
      ),
    userCanDeleteSellerOtherExpenses: changePermissionBasedOnForeignTradeStatus(
      userCanObjects.userCanDeleteSellerOtherExpenses,
    ),
    userCanEditPurchaseOtherExpenses: changePermissionBasedOnForeignTradeStatus(
      userCanObjects.userCanEditPurchaseOtherExpenses,
    ),
    userCanEditSellerOtherExpenses: changePermissionBasedOnForeignTradeStatus(
      userCanObjects.userCanEditSellerOtherExpenses,
    ),
  };

  const foreignTradeBasedAdvancePaidPermissions: SatFinancialAdvancePaidPermissions =
    {
      ...advancePaidPermissions,
      addItem: changePermissionBasedOnForeignTradeStatus(
        advancePaidPermissions.addItem,
      ),
      addRolling: changePermissionBasedOnForeignTradeStatus(
        advancePaidPermissions.addRolling,
      ),
      removeItem: changePermissionBasedOnForeignTradeStatus(
        advancePaidPermissions.removeItem,
      ),
      requestPayment: changePermissionBasedOnForeignTradeStatus(
        advancePaidPermissions.requestPayment,
      ),
      updateItem: changePermissionBasedOnForeignTradeStatus(
        advancePaidPermissions.updateItem,
      ),
    };

  const foreignTradeBasedAdvanceReceivedPermissions: SatFinancialAdvanceReceivedPermissions =
    {
      ...advanceReceivedPermissions,
      addItem: changePermissionBasedOnForeignTradeStatus(
        advanceReceivedPermissions.addItem,
      ),
      addRolling: changePermissionBasedOnForeignTradeStatus(
        advanceReceivedPermissions.addRolling,
      ),
      removeItem: changePermissionBasedOnForeignTradeStatus(
        advanceReceivedPermissions.removeItem,
      ),
      updateItem: changePermissionBasedOnForeignTradeStatus(
        advanceReceivedPermissions.updateItem,
      ),
    };

  const foreignTradeBasedBalancePaidPermissions: SatFinancialBalancePermissions =
    {
      ...balancePaidPermissions,
      addItem: changePermissionBasedOnForeignTradeStatus(
        balancePaidPermissions.addItem,
      ),
      removeItem: changePermissionBasedOnForeignTradeStatus(
        balancePaidPermissions.removeItem,
      ),
      updateItem: changePermissionBasedOnForeignTradeStatus(
        balancePaidPermissions.updateItem,
      ),
    };

  const foreignTradeBasedBalanceReceivedPermissions: SatFinancialBalancePermissions =
    {
      ...balanceReceivedPermissions,
      addItem: changePermissionBasedOnForeignTradeStatus(
        balanceReceivedPermissions.addItem,
      ),
      removeItem: changePermissionBasedOnForeignTradeStatus(
        balanceReceivedPermissions.removeItem,
      ),
      updateItem: changePermissionBasedOnForeignTradeStatus(
        balanceReceivedPermissions.updateItem,
      ),
    };

  const { showError } = useRefHook();

  const [contentState, contentDispatch] = useReducer(
    foreignTradeSupplierContentReducer,
    {},
  );

  const [financialFilesData, setFinancialFilesData] = useState<
    IFinancialFileData[]
  >([]);

  const userCanSeeResults = Object.keys(
    foreignTradeBasedUserCanObjects.userCanResultsBlock,
  ).some(key => foreignTradeBasedUserCanObjects.userCanResultsBlock[key]);

  const purchaseOtherExpensesTotalRef =
    useRef<IPurchaseOtherExpensesTotalRef>(null);

  const sellerOtherExpensesTotalRef =
    useRef<ISellerOtherExpensesTotalRef>(null);

  const [estimatedTotalAdvancePayment, setEstimatedTotalAdvancePayment] =
    useState<number>();

  const [estimatedTotalAdvanceReceived, setEstimatedTotalAdvanceReceived] =
    useState<number>();

  const [estimatedTotalBalancePayment, setEstimatedTotalBalancePayment] =
    useState<number>();

  const [estimatedTotalBalanceReceived, setEstimatedTotalBalanceReceived] =
    useState<number>();

  const [
    loadPurchaseTotalPaidData,
    { loading: purchaseTotalPaidDataLoading, data: purchaseTotalPaidData },
  ] = useLazyQuery(listPurchaseTotalPaidData, {
    variables: {
      idSatForeignTradeSupplier:
        satForeignTradeSupplier.idSatForeignTradeSupplier,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Total Paid data',
        detail: errorData.message,
      });
    },
  });

  const [
    loadSellerTotalReceivedData,
    { loading: sellerTotalReceivedDataLoading, data: sellerTotalReceivedData },
  ] = useLazyQuery(listSellerTotalReceivedData, {
    variables: {
      idSatForeignTradeSupplier:
        satForeignTradeSupplier.idSatForeignTradeSupplier,
    },
    onError: errorData => {
      showError({
        summary: 'Error while getting Total Received data',
        detail: errorData.message,
      });
    },
  });

  const [loadFinancialFiles, { loading: financialFilesLoading }] = useLazyQuery(
    listSatAttachmentsToFinancialByIdSatQuery,
    {
      variables: {
        data: {
          idSat: sat.idSat,
          idSatForeignTradeSupplier:
            satForeignTradeSupplier.idSatForeignTradeSupplier,
          idSatForeignTrade: satForeignTradeSupplier.idSatForeignTrade,
          idTypes: Object.values(financialFileTypes).filter(
            value => typeof value === 'number',
          ),
        },
      },
      onCompleted: response => {
        if (response.listSatAttachmentsToFinancialByIdSat.length) {
          setFinancialFilesData(response.listSatAttachmentsToFinancialByIdSat);
        }
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting Financial Files',
          detail: errorData.message,
        });
      },
    },
  );

  useEffect(() => {
    if (selected && !Object.keys(financialFilesData).length) {
      loadFinancialFiles();
    }
  }, [financialFilesData, loadFinancialFiles, selected]);

  return (
    <div className="flex">
      <div>
        <FormInputNumber
          name={`data[${itemIndex}].idSatForeignTradeSupplier`}
          label="ID Foreign Trade Supplier"
          hidden
        />

        <Row>
          <Purchase
            satForeignTradeSupplier={satForeignTradeSupplier}
            sat={sat}
            fieldsPermissions={fieldsPermissions}
            commonWithSatFieldsPermissions={commonWithSatFieldsPermissions}
            userCanSeePurchaseBlock={
              foreignTradeBasedUserCanObjects.userCanSeePurchaseBlock
            }
          />

          <Seller
            satForeignTradeSupplier={satForeignTradeSupplier}
            sat={sat}
            fieldsPermissions={fieldsPermissions}
            commonWithSatFieldsPermissions={commonWithSatFieldsPermissions}
            userCanSeeSellerBlock={
              foreignTradeBasedUserCanObjects.userCanSeeSellerBlock
            }
          />
        </Row>

        <Row>
          <PurchasePi
            sat={sat}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeePurchasePiBlock={
              foreignTradeBasedUserCanObjects.userCanSeePurchasePiBlock
            }
          />

          <SellerPi
            sat={sat}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeeSellerPiBlock={
              foreignTradeBasedUserCanObjects.userCanSeeSellerPiBlock
            }
          />
        </Row>

        <Row>
          <PurchaseCi
            sat={sat}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeePurchaseCiBlock={
              foreignTradeBasedUserCanObjects.userCanSeePurchaseCiBlock
            }
          />

          <SellerCi
            sat={sat}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeeSellerCiBlock={
              foreignTradeBasedUserCanObjects.userCanSeeSellerCiBlock
            }
            contentState={contentState}
          />
        </Row>

        <Row>
          {foreignTradeBasedUserCanObjects.userCanSeePurchaseOtherExpensesBlock ? (
            <PurchaseOtherExpenses
              idSatForeignTradeSupplier={
                satForeignTradeSupplier.idSatForeignTradeSupplier
              }
              selected={selected}
              fieldsPermissions={purchaseOtherExpensesFieldsPermissions}
              satCurrency={sat.idCurrency2?.abbreviation}
              userCanObjects={foreignTradeBasedUserCanObjects}
              totalRef={purchaseOtherExpensesTotalRef}
            />
          ) : (
            <div />
          )}

          {foreignTradeBasedUserCanObjects.userCanSeeSellerOtherExpensesBlock ? (
            <SellerOtherExpenses
              idSatForeignTradeSupplier={
                satForeignTradeSupplier.idSatForeignTradeSupplier
              }
              selected={selected}
              fieldsPermissions={sellerOtherExpensesFieldsPermissions}
              satCurrency={sat.idCurrency2?.abbreviation}
              userCanObjects={foreignTradeBasedUserCanObjects}
              totalRef={sellerOtherExpensesTotalRef}
            />
          ) : (
            <div />
          )}
        </Row>

        <Row>
          <PurchaseOtherExpensesTotal
            ref={purchaseOtherExpensesTotalRef}
            satForeignTradeSupplier={satForeignTradeSupplier}
            purchaseOtherExpensesTotal={contentState.purchaseOtherExpensesTotal}
            foreignTradeSupplierContentDispatch={contentDispatch}
            selected={selected}
            fieldsPermissions={purchaseOtherExpensesTotalFieldsPermissions}
            userCanSeeBlock={
              foreignTradeBasedUserCanObjects.userCanSeePurchaseOtherExpensesTotalBlock
            }
            satCurrency={sat.idCurrency2?.abbreviation}
            loadPurchaseTotalPaidData={loadPurchaseTotalPaidData}
          />

          <SellerOtherExpensesTotal
            ref={sellerOtherExpensesTotalRef}
            satForeignTradeSupplier={satForeignTradeSupplier}
            foreignTradeSupplierContentDispatch={contentDispatch}
            selected={selected}
            sellerOtherExpensesTotal={contentState.sellerOtherExpensesTotal}
            fieldsPermissions={sellerOtherExpensesTotalFieldsPermissions}
            userCanObjects={foreignTradeBasedUserCanObjects}
            apportionmentRef={apportionmentRef}
            satCurrency={sat.idCurrency2?.abbreviation}
            loadSellerTotalReceivedData={loadSellerTotalReceivedData}
          />
        </Row>

        <Row>
          <EstimatedPaymentPI
            itemIndex={itemIndex}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeeEstimatedPaymentPI={
              foreignTradeBasedUserCanObjects.userCanSeeEstimatedPaymentPI
            }
            estimatedTotalAdvance={estimatedTotalAdvancePayment}
            setEstimatedTotalAdvance={setEstimatedTotalAdvancePayment}
            estimatedTotalBalance={estimatedTotalBalancePayment}
            setEstimatedTotalBalance={setEstimatedTotalBalancePayment}
            purchaseOtherExpensesTotal={contentState.purchaseOtherExpensesTotal}
            formRef={formRef}
          />
          <EstimatedReceive
            sat={sat}
            itemIndex={itemIndex}
            satForeignTradeSupplier={satForeignTradeSupplier}
            fieldsPermissions={fieldsPermissions}
            userCanSeeEstimatedReceive={
              foreignTradeBasedUserCanObjects.userCanSeeEstimatedReceive
            }
            estimatedTotalAdvance={estimatedTotalAdvanceReceived}
            setEstimatedTotalAdvance={setEstimatedTotalAdvanceReceived}
            estimatedTotalBalance={estimatedTotalBalanceReceived}
            setEstimatedTotalBalance={setEstimatedTotalBalanceReceived}
            sellerOtherExpensesTotal={contentState.sellerOtherExpensesTotal}
            totalWithApportionment={contentState.totalWithApportionment}
          />
        </Row>
        <Row>
          <AdvancePaid
            estimatedTotalBalance={estimatedTotalBalancePayment}
            estimatedTotalAdvance={estimatedTotalAdvancePayment}
            satForeignTradeSupplier={satForeignTradeSupplier}
            selected={selected}
            fieldsPermissions={advancePaidFieldsPermissions}
            permissions={foreignTradeBasedAdvancePaidPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
            foreignTradeSupplierContentDispatch={contentDispatch}
            totalRemainCot={
              purchaseTotalPaidData
                ?.listSatForeignTradeSupplierPurchaseTotalPaid?.totalRemainCot
            }
            estimatedAdvance={satForeignTradeSupplier.estimatedAdvance}
            sat={sat}
            loadPurchaseTotalPaidData={loadPurchaseTotalPaidData}
          />
          <AdvanceReceived
            sat={sat}
            estimatedTotalAdvance={estimatedTotalAdvanceReceived}
            estimatedTotalBalance={estimatedTotalBalanceReceived}
            satForeignTradeSupplier={satForeignTradeSupplier}
            selected={selected}
            fieldsPermissions={advanceReceivedFieldsPermissions}
            permissions={foreignTradeBasedAdvanceReceivedPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
            foreignTradeSupplierContentDispatch={contentDispatch}
            totalRemainSun={
              sellerTotalReceivedData
                ?.listSatForeignTradeSupplierSellerTotalReceived?.totalRemainSun
            }
            loadSellerTotalReceivedData={loadSellerTotalReceivedData}
          />
        </Row>
        <Row>
          <Balance
            paymentType={SatForeignTradePaymentType.BALANCE_PURCHASE}
            satForeignTradeSupplier={satForeignTradeSupplier}
            selected={selected}
            fieldsPermissions={balancePaidFieldsPermissions}
            permissions={foreignTradeBasedBalancePaidPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
            foreignTradeSupplierContentDispatch={contentDispatch}
            contentState={contentState}
            estimatedTotalBalance={estimatedTotalBalancePayment}
            estimatedTotalAdvance={estimatedTotalAdvancePayment}
            loadPurchaseOrSellerTotalData={loadPurchaseTotalPaidData}
          />
          <Balance
            paymentType={SatForeignTradePaymentType.BALANCE_SELLER}
            satForeignTradeSupplier={satForeignTradeSupplier}
            selected={selected}
            fieldsPermissions={balanceReceivedFieldsPermissions}
            permissions={foreignTradeBasedBalanceReceivedPermissions}
            satCurrency={sat.idCurrency2?.abbreviation}
            foreignTradeSupplierContentDispatch={contentDispatch}
            contentState={contentState}
            estimatedTotalBalance={estimatedTotalBalanceReceived}
            estimatedTotalAdvance={estimatedTotalAdvanceReceived}
            loadPurchaseOrSellerTotalData={loadSellerTotalReceivedData}
          />
        </Row>
        <Row>
          <PurchaseTotalPaid
            fieldsPermissions={totalPaidFieldsPermissions}
            userCanSee={foreignTradeBasedUserCanObjects.userCanSeeTotalPaid}
            satCurrency={sat.idCurrency2?.abbreviation}
            totalPaid={
              purchaseTotalPaidData
                ?.listSatForeignTradeSupplierPurchaseTotalPaid?.totalPaid
            }
            totalRemainCot={
              purchaseTotalPaidData
                ?.listSatForeignTradeSupplierPurchaseTotalPaid?.totalRemainCot
            }
            totalPaidLoading={purchaseTotalPaidDataLoading}
          />
          <SellerTotalReceived
            fieldsPermissions={totalReceivedFieldsPermissions}
            userCanSee={foreignTradeBasedUserCanObjects.userCanSeeTotalReceived}
            satCurrency={sat.idCurrency2?.abbreviation}
            totalReceived={
              sellerTotalReceivedData
                ?.listSatForeignTradeSupplierSellerTotalReceived?.totalReceived
            }
            totalRemainSun={
              sellerTotalReceivedData
                ?.listSatForeignTradeSupplierSellerTotalReceived?.totalRemainSun
            }
            totalReceivedLoading={sellerTotalReceivedDataLoading}
          />
        </Row>
        <Row>
          <Files
            idSat={sat.idSat}
            idSatForeignTradeSupplier={
              satForeignTradeSupplier.idSatForeignTradeSupplier
            }
            financialFileTypesData={financialPurchaseFileTypesData}
            financialFileTypesLoading={financialPurchaseFileTypesLoading}
            financialFilesGroupId={DomainGroup.FINANCIAL_PURCHASE_TYPE_FILES}
            selected={selected}
            permissions={purchaseFilesPermissions}
            financialFilesData={financialFilesData.filter(file =>
              Object.values(FinancialPurchaseFiles).includes(
                file.idAttachment2?.idType ?? '',
              ),
            )}
            financialFilesLoading={financialFilesLoading}
            loadFinancialFiles={loadFinancialFiles}
          />
          <div>
            <Files
              idSat={sat.idSat}
              idSatForeignTradeSupplier={
                satForeignTradeSupplier.idSatForeignTradeSupplier
              }
              financialFileTypesData={financialSellerFileTypesData}
              financialFileTypesLoading={financialSellerFileTypesLoading}
              financialFilesGroupId={DomainGroup.FINANCIAL_SELLER_TYPE_FILES}
              selected={selected}
              permissions={sellerFilesPermissions}
              financialFilesData={financialFilesData.filter(file =>
                Object.values(FinancialSellerFiles).includes(
                  file.idAttachment2?.idType ?? '',
                ),
              )}
              financialFilesLoading={financialFilesLoading}
              loadFinancialFiles={loadFinancialFiles}
            />
            <FinancialForeignTradeFiles
              financialForeignTradeFileTypesData={
                financialForeignTradeFileTypesData
              }
              financialForeignTradeFileTypesLoading={
                financialForeignTradeFileTypesLoading
              }
              financialFilesData={financialFilesData.filter(
                file =>
                  file.idAttachment2?.idType ===
                  ForeignTradeTypeFiles.CI_PL_SIGNED,
              )}
              financialFilesLoading={financialFilesLoading}
            />
          </div>
        </Row>
        <Row>
          <UsersPurchase
            itemIndex={itemIndex}
            sat={sat}
            fieldsPermissions={usersPurchaseFieldsPermissions}
            userCanSee={foreignTradeBasedUserCanObjects.userCanSeeUsersPurchase}
          />
          <UsersSeller
            itemIndex={itemIndex}
            sat={sat}
            fieldsPermissions={usersSellerFieldsPermissions}
            userCanSee={foreignTradeBasedUserCanObjects.userCanSeeUsersSeller}
          />
        </Row>
      </div>

      {userCanSeeResults && (
        <Results
          satForeignTradeSupplier={satForeignTradeSupplier}
          satCurrencyAbbreviation={sat.idCurrency2?.abbreviation}
          contentState={contentState}
          fieldsPermissions={resultsFieldsPermissions}
          userCan={foreignTradeBasedUserCanObjects.userCanResultsBlock}
        />
      )}
    </div>
  );
};

export default Content;
