import { ApolloQueryResult, useMutation } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import {
  Column,
  ColumnBodyOptions,
  ColumnEditorOptions,
} from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { reconcileFinancialTransactionQuery } from './queries';
import { useRefHook } from '../../../../hooks/useRefHook';
import { FinancialTransaction } from '../interfaces';
import Button from '../../../../components/Button';
import Grid from '../../../../components/Grid';
import { parseCurrencyPtBrNumberColumn } from '../../../../utils/gridColumnsParse';
import isNullOrUndefined from '../../../../utils/isNullOrUndefined';
import { gridColumnsData } from './constants';
import { ColumnData } from '../../../../components/Grid/interfaces';
import { renderNumber } from '../../../../utils/formatLocale';

interface IReconcilePaymentModalProps {
  isVisibleReconcilePaymentsModal: boolean;
  setIsVisibleReconcilePaymentsModal(e: boolean): void;
  selectedFinancialTransactions: FinancialTransaction[];
  setSelectedFinancialTransactions(e: FinancialTransaction[]): void;
  setLoading(e: boolean): void;
  gridDataRefetch(): Promise<ApolloQueryResult<any>>;
}

const ReconcilePaymentModal: React.FC<IReconcilePaymentModalProps> = ({
  isVisibleReconcilePaymentsModal,
  setIsVisibleReconcilePaymentsModal,
  selectedFinancialTransactions,
  setSelectedFinancialTransactions,
  setLoading,
  gridDataRefetch,
}) => {
  const { showSuccess, showError } = useRefHook();

  const initialData = selectedFinancialTransactions.map(
    financialTransaction => {
      return {
        ...financialTransaction,
        billRate:
          financialTransaction.billRate ??
          financialTransaction.idFinancialAccount2?.idCurrency2
            ?.usdExchangeRate,
      };
    },
  );

  // Método para chamar a mutation
  const [financialTransactions, setFinancialTransactions] =
    useState(initialData);

  // Método para chamar a mutation
  const [reconcileFinancialTransactionMutation] = useMutation(
    reconcileFinancialTransactionQuery,
  );

  const onHide = () => {
    setIsVisibleReconcilePaymentsModal(false);
  };

  async function handleSubmit() {
    setLoading(true);

    const dataToSubmit = financialTransactions.map(ft => {
      return {
        idFinancialTransaction: ft.idFinancialTransaction,
        billRate: ft.billRate,
        competencyRegime: ft.competencyRegime,
        cashflowRegime: ft.cashflowRegime,
      };
    });

    try {
      await reconcileFinancialTransactionMutation({
        variables: {
          data: dataToSubmit,
        },
      });

      await gridDataRefetch();

      showSuccess({
        summary: `Payments reconciliated successfully`,
      });
    } catch (error) {
      showError({
        summary: `Error while reconciliating payments`,
        detail: error.message,
      });
    } finally {
      setLoading(false);
      onHide();
      setSelectedFinancialTransactions([]);
    }
  }

  const renderFooter = () => {
    return (
      <div className="flex gap-2 justify-content-end pt-5">
        <Button
          label="Confirm"
          icon="pi pi-check"
          type="button"
          onClick={handleSubmit}
        />
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={onHide}
          severity="danger"
        />
      </div>
    );
  };

  const onEditorValueChange = useCallback(
    (options: ColumnEditorOptions, value: any) => {
      const itemIndex = options.rowIndex;

      const updatedFinancialTransactions = options.props.value;

      const updatedFinancialTransaction =
        updatedFinancialTransactions[itemIndex];

      updatedFinancialTransaction[options.field] = value;

      setFinancialTransactions([...updatedFinancialTransactions]);
    },
    [],
  );

  const dateInputField = useCallback(
    (props: ColumnEditorOptions) => {
      return (
        <InputText
          defaultValue={props.rowData[props.field]}
          onChange={e => onEditorValueChange(props, e.currentTarget.value)}
          onClick={e => e.stopPropagation()}
          type="date"
        />
      );
    },
    [onEditorValueChange],
  );

  const billRateInputField = useCallback(
    (props: ColumnEditorOptions) => {
      const isDollar =
        props.rowData.idFinancialAccount2?.idCurrency2?.abbreviation === 'USD';
      return (
        <InputNumber
          value={props.rowData.billRate}
          onChange={e => onEditorValueChange(props, e.value)}
          onClick={e => e.stopPropagation()}
          mode="decimal"
          maxFractionDigits={4}
          disabled={isDollar}
        />
      );
    },
    [onEditorValueChange],
  );

  function parseAmountColumn(
    rowData: FinancialTransaction,
    props: ColumnBodyOptions,
  ) {
    const bodyClassName =
      rowData.amount > 0 ? 'text-green-400' : 'text-red-400';

    return (
      <span className={bodyClassName}>
        {parseCurrencyPtBrNumberColumn(
          rowData,
          props,
          2,
          rowData.idFinancialAccount2?.idCurrency2?.abbreviation ?? undefined,
        )}
      </span>
    );
  }

  function parseAmountUsdColumn(rowData: FinancialTransaction) {
    if (
      isNullOrUndefined(rowData.amount) ||
      isNullOrUndefined(rowData.billRate)
    ) {
      return '';
    }

    const amountUsd = rowData.amount / rowData.billRate;

    return amountUsd.toLocaleString('pt', {
      style: 'currency',
      currency: 'USD',
    });
  }

  const columns: ColumnData[] = useMemo(() => {
    return Object.values(gridColumnsData).map(column => column);
  }, []);

  function renderColumnBody(field: string) {
    switch (field) {
      case gridColumnsData.amount.field:
        return parseAmountColumn;
      case gridColumnsData.amountUsd.field:
        return parseAmountUsdColumn;
      default:
        return undefined;
    }
  }

  function setColumnSize(column: string) {
    switch (column) {
      case gridColumnsData.billRate.field:
        return { width: '2em' };
      case gridColumnsData.clientName.field:
      case gridColumnsData.supplierName.field:
      case gridColumnsData.competencyRegime.field:
      case gridColumnsData.cashflowRegime.field:
        return { width: '4em' };
      default:
        return { width: '3em' };
    }
  }

  function renderColumnEditor(field: string) {
    switch (field) {
      case gridColumnsData.billRate.field:
        return billRateInputField;
      case gridColumnsData.competencyRegime.field:
      case gridColumnsData.cashflowRegime.field:
        return dateInputField;
      default:
        return undefined;
    }
  }

  function sumColumnAmount(isAmountUsdColumn = false) {
    const status = new Set(
      financialTransactions.map(rowData => rowData.status),
    );

    // Os status das financial transactions selecionadas devem ser o mesmo para que a soma seja realizada
    if (status.size === 1) {
      return financialTransactions.reduce((total, rowData) => {
        return (
          total +
          (isAmountUsdColumn
            ? rowData.amount / rowData.billRate
            : rowData.amount)
        );
      }, 0);
    }

    return null;
  }

  function renderColumnTotalAmountAndCurrencyAbbreviation() {
    const currencies: string[] = [];

    financialTransactions.forEach((rowData: FinancialTransaction) => {
      const currencyAbbreviation =
        rowData.idFinancialAccount2?.idCurrency2?.abbreviation;

      if (currencyAbbreviation && !currencies.includes(currencyAbbreviation)) {
        currencies.push(currencyAbbreviation);
      }
    });

    // Caso existir somente um currency abbreviation em currencies, realiza a soma, caso não, retorna nulo
    if (currencies.length === 1) {
      const totalAmount = sumColumnAmount();
      return {
        amount: totalAmount,
        currencyAbbreviation: currencies[0],
      };
    }

    return { amount: null, currencyAbbreviation: null };
  }

  function handleColumnFooter(field: string) {
    if (field === gridColumnsData.amount.field) {
      const { amount: totalAmount, currencyAbbreviation } =
        renderColumnTotalAmountAndCurrencyAbbreviation();

      if (currencyAbbreviation === null || totalAmount === null) {
        return null;
      }

      const totalAmountStyleClass =
        totalAmount > 0 ? 'text-green-600' : 'text-red-500';

      return (
        <span className={totalAmountStyleClass}>
          {renderNumber(totalAmount, 'currency', currencyAbbreviation)}
        </span>
      );
    }

    if (field === gridColumnsData.amountUsd.field) {
      const totalAmountUSD = sumColumnAmount(true);

      if (totalAmountUSD === null) {
        return null;
      }

      return <span>{renderNumber(totalAmountUSD, 'currency', 'USD')}</span>;
    }

    return null;
  }

  const dynamicColumns = columns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          field={col.field}
          body={renderColumnBody(col.field)}
          header={col.header}
          style={setColumnSize(col.field)}
          editor={renderColumnEditor(col.field)}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  return (
    <Dialog
      header="Reconcile Payments"
      visible={isVisibleReconcilePaymentsModal}
      onHide={onHide}
      style={{ width: '1450px' }}
      footer={renderFooter}
    >
      <Grid
        className="p-datatable-hoverable-rows overflow-hidden"
        name="paymentReconciliation"
        value={financialTransactions}
        emptyMessage="No payments to reconciliate."
        editMode="row"
        selectionAutoFocus={false}
        editingRows={financialTransactions}
        onRowEditChange={() => ''}
        tableStyle={{
          tableLayout: 'fixed',
        }}
        paginator={false}
      >
        {dynamicColumns}
      </Grid>
    </Dialog>
  );
};

export default ReconcilePaymentModal;
