import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { FiChevronDown, FiChevronUp, FiExternalLink } from 'react-icons/fi';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import {
  DataTablePageEvent,
  DataTableRowClickEvent,
  DataTableSortEvent,
} from 'primereact/datatable';
import { Link, useHistory } from 'react-router-dom';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { get, sortBy } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import { confirmDialog } from 'primereact/confirmdialog';
import {
  deleteFinancialTransactionMutation,
  exportFinancialTransactionsQuery,
  getSumDataFromFinancialTransactionsQuery,
  listAllFinancialTransactionsQuery,
} from './queries';
import {
  FinancialTransaction,
  FinancialTransactionsFieldsSearch,
  FinancialTransactionsLazyParams,
} from './interfaces';
import pagination, { searchDelayMiliseconds } from '../../../config/pagination';
import { useRefHook } from '../../../hooks/useRefHook';
import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';
import AdvancedFiltersPanel from '../../../components/AdvancedFiltersPanel';
import { ColumnData } from '../../../components/Grid/interfaces';
import { gridColumnsData } from './constants';
import updateLocalStorageInDb from '../../../utils/updateLocalStorageInDb';
import { expandOrCollapseHeader } from '../../../services/layoutsDefinitions';
import Grid, { GridRef } from '../../../components/Grid';
import MultiSelect from '../../../components/Grid/MultiSelect';
import Loading from '../../../components/Loading';
import Empty from '../../../components/Empty';
import Tag from '../../../components/Tag';
import useTitle from '../../../hooks/useTitle';
import StatusTag from '../../../components/StatusTag';
import { parseDateColumm } from '../../../utils/gridColumnsParse';
import { GridActions } from '../../../components/Grid/styles';
import { renderNumber } from '../../../utils/formatLocale';
import MainButton from '../../../components/MainButton';
import FinancialTransactionAlerts from '../FinancialTransactionAlerts';

const screenSubject = 'Bills To Pay/To Receive';
const pageTitle = `List of ${screenSubject}`;
const advancedFiltersName = '@SAT:financialTransactionsAdvancedFilters';
const lazyParamsName = '@SAT:financialTransactionsLazyParams';
const gridColumnsName = '@SAT:financialTransactionGridColumns';
const gridName = 'financialTransactions';

const FinancialTransactions: React.FC = () => {
  const history = useHistory();

  const advancedFiltersPanelRef = useRef<OverlayPanel>(null);
  const gridRef = useRef<GridRef<any[]>>(null);
  const isMounted = useRef(false);
  const { showError, showSuccess } = useRefHook();

  /**
   * Colunas da grid que o usuario tem permissao de visualizar
   */
  const columns: ColumnData[] = useMemo(() => {
    return Object.values(gridColumnsData).map(column => column);
  }, []);

  const [lazyParams, setLazyParams] = useState<FinancialTransactionsLazyParams>(
    () => {
      const localStorageLazyParamsData = localStorage.getItem(lazyParamsName);

      if (localStorageLazyParamsData) {
        return JSON.parse(localStorageLazyParamsData);
      }

      return pagination.initialLazyParams;
    },
  );

  const filteredColumnsHeader = useMemo(() => {
    return columns.filter(field =>
      Object.keys(lazyParams).find(
        key =>
          lazyParams[key as keyof FinancialTransactionsLazyParams] &&
          key === field.advancedFilterField,
      ),
    );
  }, [columns, lazyParams]);

  const fieldsSearch: FinancialTransactionsFieldsSearch = useMemo(() => {
    return Object.entries(lazyParams).reduce(
      (acc, [key, value]) => {
        const column = columns.find(col => col.advancedFilterField === key);

        if (column) acc[key] = value;

        return acc;
      },
      {
        globalSearch: lazyParams.globalSearch,
      } as { [key: string]: string | null | number | undefined },
    );
  }, [columns, lazyParams]);

  const [fixedStickyButtons, setFixedStickyButtons] = useState(false);
  const [showAppliedFiltersOnly, setShowAppliedFiltersOnly] = useState(false);
  const [hasFilterApplied, setHasFilterApplied] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<ColumnData[]>(() => {
    // Busca colunas selecionadas salvas no local storage
    const localStorageSelectedColumns = localStorage.getItem(gridColumnsName);

    // Se encontrou, salva as colunas no estado
    if (localStorageSelectedColumns) {
      return JSON.parse(localStorageSelectedColumns);
    }

    return columns;
  });
  const [globalFilter, setGlobalFilter] = useState(
    lazyParams.globalSearch || '',
  );
  const [selectedFinancialTransactions, setSelectedFinancialTransactions] =
    useState<FinancialTransaction[]>([]);

  const [pageLoading, setPageLoading] = useState(false);

  const [deleteFinancialTransactions] = useMutation(
    deleteFinancialTransactionMutation,
  );

  useTitle(pageTitle);

  const {
    loading: gridDataLoading,
    data: gridData,
    refetch: financialTransactionsRefetch,
  } = useQuery(listAllFinancialTransactionsQuery, {
    variables: {
      data: {
        pagination: {
          _page: lazyParams.page + 1,
          _limit: lazyParams.rows,
          _orderBy: lazyParams.sortField,
          _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
        },
        fieldsSearch,
      },
    },
    onError: errorData => {
      showError({
        summary: `Error while getting ${screenSubject}`,
        detail: errorData.message,
      });
    },
  });

  const { loading: listSumLoading, data: listSumData } = useQuery(
    getSumDataFromFinancialTransactionsQuery,
    {
      variables: {
        data: fieldsSearch,
      },
      onError: errorData => {
        showError({
          summary: 'Error while getting list totals',
          detail: errorData.message,
        });
      },
    },
  );

  const [exportFinancialTransactions, { loading: exportLoading }] =
    useLazyQuery(exportFinancialTransactionsQuery, {
      onCompleted: response => {
        if (response.exportFinancialTransactionsList) {
          window.open(response.exportFinancialTransactionsList, '_blank');
        } else {
          showError({
            summary: 'Error while exporting list',
          });
        }
      },

      onError: error => {
        showError({
          summary: 'Error while exporting list',
          detail: error.message,
        });
      },
    });

  function renderColumnHeader(headerName: string) {
    return (
      <span className="custom-header">
        {headerName}
        {/* Se houver filtro aplicado na coluna, adiciona icone de filtro */}

        {filteredColumnsHeader.find(filter => filter.header === headerName) ? (
          <i className="pi pi-filter" />
        ) : null}
      </span>
    );
  }

  function renderStatusColumn(rowData: FinancialTransaction) {
    return (
      <Tag
        value={
          rowData[gridColumnsData.status.field as keyof FinancialTransaction]
        }
        severity={rowData.amount >= 0 ? 'success' : 'danger'}
      />
    );
  }

  function renderPaymentSituationDescriptionColumn(
    rowData: FinancialTransaction,
  ) {
    return (
      <StatusTag
        className="status-tag"
        domain={rowData.idPaymentSituation}
        status={rowData.idPaymentSituation2.description}
      />
    );
  }

  function renderLargeTextColumn(
    rowData: FinancialTransaction,
    options: ColumnBodyOptions,
  ) {
    const value = get(rowData, options.field);

    if (!value) return undefined;

    return (
      <span className="s-grid-break-text" title={value.toString()}>
        {value}
      </span>
    );
  }

  function renderActionsColumn(rowData: FinancialTransaction) {
    return (
      <GridActions>
        <Link
          to={`/financial/transactions/${rowData.idFinancialTransaction}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={e => e.stopPropagation()}
          title="Open in new tab"
        >
          <FiExternalLink size={15} />
        </Link>
        {rowData.hasSystemGeneratedComment && (
          <FinancialTransactionAlerts
            idFinancialTransaction={rowData.idFinancialTransaction}
          />
        )}
      </GridActions>
    );
  }

  function renderAmountColumn(
    amount: number,
    currencyAbbreviation: string | null,
  ) {
    const value = renderNumber(
      amount,
      'currency',
      currencyAbbreviation ?? undefined,
    );

    return (
      <span
        className={`s-grid-break-text ${
          amount >= 0 ? 'text-green-600' : 'text-red-500'
        }`}
        title={value}
      >
        {value}
      </span>
    );
  }

  function renderAmountUSDColumn(amountUSD: number) {
    const value = renderNumber(amountUSD, 'currency', 'USD');

    return (
      <span
        className={`s-grid-break-text ${
          amountUSD >= 0 ? 'text-green-600' : 'text-red-500'
        }`}
        title={value}
      >
        {value}
      </span>
    );
  }

  function renderAmountRowData(rowData: FinancialTransaction) {
    return renderAmountColumn(
      rowData.amount,
      rowData.idFinancialAccount2.idCurrency2.abbreviation,
    );
  }

  function renderAmountUSDRowData(rowData: FinancialTransaction) {
    return renderAmountUSDColumn(rowData.amountUSD);
  }

  function renderShipmentColumn(rowData: FinancialTransaction) {
    if (!rowData.idSatForeignTrade2) return undefined;

    return (
      <Link
        to={`/commercial/sats/${rowData.idSatForeignTrade2.idSat}?tab=foreignTrade&foreignTradeId=${rowData.idSatForeignTrade}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {rowData.idSatForeignTrade2.satForeignTradeNumber}
        <FiExternalLink size={15} />
      </Link>
    );
  }

  function renderColumn(field: string) {
    switch (field) {
      case gridColumnsData.status.field:
        return renderStatusColumn;
      case gridColumnsData.paymentSituationDescription.field:
        return renderPaymentSituationDescriptionColumn;
      case gridColumnsData.reference.field:
      case gridColumnsData.financialAccountCompanyName.field:
      case gridColumnsData.financialTransactionCategoryName.field:
      case gridColumnsData.financialTransactionCategoryGroup.field:
      case gridColumnsData.countryName.field:
      case gridColumnsData.costCenterByCountryName.field:
      case gridColumnsData.clientName.field:
      case gridColumnsData.supplierName.field:
      case gridColumnsData.product.field:
      case gridColumnsData.description.field:
      case gridColumnsData.satForeignTradeShipmentStatusDescription.field:
      case gridColumnsData.idBeneficiary.field:
        return renderLargeTextColumn;
      case gridColumnsData.amount.field:
        return renderAmountRowData;
      case gridColumnsData.amountUSD.field:
        return renderAmountUSDRowData;
      case gridColumnsData.paymentForecast.field:
      case gridColumnsData.competencyRegime.field:
      case gridColumnsData.cashflowRegime.field:
      case gridColumnsData.reconciledAt.field:
        return parseDateColumm;
      case gridColumnsData.idSatForeignTrade.field:
        return renderShipmentColumn;
      default:
        return undefined;
    }
  }

  function setColumnSize(column: string) {
    switch (column) {
      case gridColumnsData.countryName.field:
      case gridColumnsData.financialTransactionCategoryGroup.field:
      case gridColumnsData.financialAccountCurrencyAbbreviation.field:
        return { width: '130px' };
      case gridColumnsData.reference.field:
        return { width: '135px' };
      case gridColumnsData.idSatForeignTrade.field:
      case gridColumnsData.idBeneficiary.field:
        return { width: '150px' };
      case gridColumnsData.paymentForecast.field:
        return { width: '175px' };
      case gridColumnsData.paymentSituationDescription.field:
      case gridColumnsData.cashflowRegime.field:
      case gridColumnsData.financialTransactionCategoryName.field:
      case gridColumnsData.clientName.field:
      case gridColumnsData.supplierName.field:
      case gridColumnsData.product.field:
      case gridColumnsData.description.field:
      case gridColumnsData.satForeignTradeShipmentStatusDescription.field:
      case gridColumnsData.reconciledAt.field:
        return { width: '190px' };
      case gridColumnsData.competencyRegime.field:
        return { width: '210px' };
      case gridColumnsData.costCenterByCountryName.field:
        return { width: '220px' };
      default:
        return { width: '140px' };
    }
  }

  function handleColumnFooter(field: string) {
    if (listSumLoading || gridDataLoading) return <Skeleton />;
    // Currency para adicionar nos campos de moeda - sao listados se todas as
    // transactions possuirem a mesma currency
    const currencyAbbreviation =
      gridData?.listAllFinancialTransactions?.data[0]?.idFinancialAccount2
        .idCurrency2.abbreviation;

    const value = listSumData?.getSumDataFromFinancialTransactionsList[field];

    switch (field) {
      case 'amount':
        return renderAmountColumn(value, currencyAbbreviation);
      case 'amountUSD':
        return renderAmountUSDColumn(value);
      default:
        return undefined;
    }
  }

  const dynamicColumns = selectedColumns.map(col => {
    return (
      col.header &&
      col.field && (
        <Column
          key={col.field}
          field={col.field}
          body={renderColumn(col.field)}
          header={renderColumnHeader(col.header)}
          style={setColumnSize(col.field)}
          sortable={col.field !== gridColumnsData.amountUSD.field}
          footer={handleColumnFooter(col.field)}
        />
      )
    );
  });

  function saveLazyParams(newLazyParams: FinancialTransactionsLazyParams) {
    localStorage.setItem(lazyParamsName, JSON.stringify(newLazyParams));
    updateLocalStorageInDb(lazyParamsName, newLazyParams);
  }

  function expandCollapsePageHeader() {
    const collapseOrnot = expandOrCollapseHeader(fixedStickyButtons);
    return setFixedStickyButtons(collapseOrnot[1]);
  }

  function onColumnToggle(event: MultiSelectChangeEvent) {
    const newSelectedColumns = event.value;
    const orderedSelectedColumns = columns.filter(col =>
      newSelectedColumns.some(
        (sCol: { field: string }) => sCol.field === col.field,
      ),
    );

    // Salva colunas selecionadas no local storage
    localStorage.setItem(
      gridColumnsName,
      JSON.stringify(orderedSelectedColumns),
    );
    setSelectedColumns(orderedSelectedColumns);

    // Atualiza colunas em banco
    updateLocalStorageInDb(gridColumnsName, orderedSelectedColumns);
  }

  function onPage(event: DataTablePageEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          first: event.first,
          rows: event.rows,
          page: event.page ?? current.page,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
      gridRef.current?.resetScroll();
    }
  }

  function onSort(event: DataTableSortEvent) {
    if (isMounted.current) {
      setLazyParams(current => {
        const newLazyParams = {
          ...current,
          multiSortMeta: event.multiSortMeta,
          sortField: event.sortField,
          sortOrder: event.sortOrder,
        };

        saveLazyParams(newLazyParams);

        return newLazyParams;
      });
    }
  }

  function onRowClick(e: DataTableRowClickEvent) {
    history.push(`/financial/transactions/${e.data.idFinancialTransaction}`);
  }

  /**
   * Busca colunas selecionadas na ordem em que sao apresentadas ao usuario
   * @returns Colunas selecionadas na ordem em que sao apresentadas ao usuario
   */
  function getOrderedSelectedColumns() {
    const columnsOrder = gridRef.current?.columnOrder;

    if (columnsOrder && columnsOrder.length) {
      // Ordena itens de acordo com o array columnsOrder. Itens que nao existam
      // em columnsOrder sao colocados no final do array
      const sortedSelectedColumns = sortBy(selectedColumns, item => {
        const index = columnsOrder.indexOf(item.field);
        return index === -1 ? Infinity : index;
      });

      return sortedSelectedColumns.map(col => col.field);
    }

    // Se nao houver informacao de ordenacao das colunas, retorna colunas
    // selecionadas na ordem default
    return selectedColumns.map(col => col.field);
  }

  function handleDeleteSelected() {
    confirmDialog({
      message: `Are you sure you want to delete ${selectedFinancialTransactions.length} Bills To Pay / To Receive?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: async () => {
        setPageLoading(true);
        // Extrai apenas o ID de Financial Transaction
        const idsToDelete = selectedFinancialTransactions.map(
          financialTransaction => financialTransaction.idFinancialTransaction,
        );
        try {
          // Deleta Financial Transactions
          await deleteFinancialTransactions({
            variables: {
              financialTransactionsIds: idsToDelete,
            },
          });

          // Exibe toast de sucesso
          showSuccess({
            summary: 'Deleted',
            detail: `You have deleted ${selectedFinancialTransactions.length} Bills To Pay / To Receive`,
          });

          // Zera estado de Financial Transactions selecionadas
          setSelectedFinancialTransactions([]);

          // Atualiza grid
          await financialTransactionsRefetch();
        } catch (error) {
          showError({
            summary: 'Error while deleting Bills To Pay / To Receive',
            detail: error.message,
          });
        } finally {
          setPageLoading(false);
        }
      },
    });
  }

  /**
   * Atualiza o filtro global da grid
   */
  useEffect(() => {
    // Evita que o filtro seja atualizado na montagem do componente
    if (isMounted.current) {
      const { first, page } = pagination.initialLazyParams;

      const delayDebounceFn = setTimeout(() => {
        setLazyParams(current => {
          const newLazyParams = {
            ...current,
            first,
            page,
            globalSearch: globalFilter,
          };

          saveLazyParams(newLazyParams);

          return newLazyParams;
        });
      }, searchDelayMiliseconds);

      return () => clearTimeout(delayDebounceFn);
    }

    isMounted.current = true;
    return undefined;
  }, [globalFilter]);

  useEffect(() => {
    const filtersApplied = Object.entries(lazyParams).filter(([key, value]) => {
      const isKeyAColumn = Object.values(columns).some(
        column => column.advancedFilterField === key,
      );
      return isKeyAColumn && value;
    });
    setHasFilterApplied(filtersApplied && filtersApplied.length > 0);
  }, [columns, lazyParams]);

  return (
    <div className="flex flex-column overflow-hidden">
      <PageHeader title={pageTitle} fixedStickyButtons={fixedStickyButtons}>
        <MainButton
          className="mainButton"
          label="New Bill To Pay / To Receive"
          onClick={() => history.push('/financial/transactions/create')}
        />

        <Button
          className="advanced-filters-button"
          label="Advanced Filters"
          onClick={e => {
            setShowAppliedFiltersOnly(false);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
        />
        <Button
          label="Delete selected"
          className="p-button-danger"
          severity="danger"
          disabled={selectedFinancialTransactions.length === 0}
          onClick={handleDeleteSelected}
        />
        <Button
          className="applied-filters-button"
          icon={`pi ${hasFilterApplied ? 'pi-filter-fill' : 'pi-filter'}`}
          onClick={e => {
            setShowAppliedFiltersOnly(true);
            advancedFiltersPanelRef.current?.toggle(e, e.target);
          }}
          disabled={!hasFilterApplied}
        />
        <AdvancedFiltersPanel
          className="advanced-filters-form"
          innerRef={advancedFiltersPanelRef}
          fields={columns}
          advancedFiltersName={advancedFiltersName}
          appliedFiltersOnly={showAppliedFiltersOnly}
          onApply={e =>
            setLazyParams(current => {
              const newLazyParams = {
                ...current,
                ...e,
                first: pagination.initialLazyParams.first,
                page: pagination.initialLazyParams.page,
                rows: pagination.initialLazyParams.rows,
              };

              saveLazyParams(newLazyParams);

              return newLazyParams;
            })
          }
          onClear={() =>
            setLazyParams(() => {
              const newLazyParams = {
                ...pagination.initialLazyParams,
                globalSearch: globalFilter,
              };

              saveLazyParams(newLazyParams);

              return newLazyParams;
            })
          }
        />
        <button
          className="collapseHeader"
          type="button"
          onClick={expandCollapsePageHeader}
        >
          {fixedStickyButtons ? (
            <FiChevronDown className="chevronIcon" size={20} />
          ) : (
            <FiChevronUp className="chevronIcon" size={20} />
          )}
        </button>

        <Button
          label="Export Grid"
          className="export-xlsx"
          loading={exportLoading}
          onClick={() =>
            exportFinancialTransactions({
              variables: {
                data: {
                  pagination: {
                    _orderBy: lazyParams.sortField,
                    _sortOrder: lazyParams.sortOrder === -1 ? 'DESC' : 'ASC',
                  },
                  columnsToExport: getOrderedSelectedColumns(),
                  fieldsSearch,
                },
              },
            })
          }
        />

        <MultiSelect
          gridRef={gridRef}
          className="grid-multiselect-panel"
          value={selectedColumns}
          options={columns.filter(column => column.field && column.header)}
          onChange={onColumnToggle}
          dataKey="field"
        />
        <InputText
          className="gridSearch"
          type="search"
          value={globalFilter}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Search for an item"
        />
      </PageHeader>
      <Grid
        ref={gridRef}
        name={gridName}
        lazy
        totalRecords={gridData?.listAllFinancialTransactions?.items ?? 0}
        value={gridData?.listAllFinancialTransactions?.data ?? []}
        globalFilter={globalFilter}
        emptyMessage="No items found."
        reorderableColumns
        removableSort
        scrollable
        scrollHeight="flex"
        rows={lazyParams.rows}
        first={lazyParams.first}
        onPage={onPage}
        onSort={onSort}
        sortField={lazyParams.sortField}
        sortOrder={lazyParams.sortOrder}
        onRowClick={onRowClick}
        selection={selectedFinancialTransactions}
        onSelectionChange={e => setSelectedFinancialTransactions(e.value)}
      >
        <Column
          columnKey="multiple"
          style={{ width: '1rem' }}
          selectionMode="multiple"
          reorderable={false}
        />
        <Column
          columnKey="actions"
          style={{ width: '6rem' }}
          body={renderActionsColumn}
          reorderable={false}
        />
        {dynamicColumns}
      </Grid>
      {(pageLoading || gridDataLoading) && <Loading />}
      {columns && !columns.length && (
        <Empty message="You do not have permission to see any columns">
          <i className="pi pi-exclamation-circle" />
        </Empty>
      )}
      {columns && columns.length && !selectedColumns.length && (
        <Empty message="You need to select at least one column to see any data" />
      )}
    </div>
  );
};

export default FinancialTransactions;
