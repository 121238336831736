import { gql } from '@apollo/client';

export const listFinancialTransactionCommentsQuery = gql`
  query listFinancialTransactionComments(
    $data: ListFinancialTransactionCommentsInput!
  ) {
    listFinancialTransactionComments(data: $data) {
      data {
        idFinancialTransactionComment
        idFinancialTransaction
        idComment
        versionLock
        idComment2 {
          idComment
          message
          isRestrictInfo
          isGeneratedSystem
          createdAt
          createdBy
          childrenCount
          createdBy2 {
            idUser
            username
            avatarUrl
            firstName
            lastName
            fullName
          }
          idStatus
          idStatus2 {
            idDomain
            description
          }
          updatedAt
          updatedBy2 {
            username
          }
          idAttachment2 {
            url
            name
            nameGivenByUser
          }
        }
      }
      items
    }
  }
`;

export const createFinancialTransactionCommentQuery = gql`
  mutation CreateFinancialTransactionComment(
    $data: CreateFinancialTransactionCommentInput!
  ) {
    createFinancialTransactionComment(data: $data) {
      data {
        idFinancialTransactionComment
      }
    }
  }
`;

export const deleteFinancialTransactionCommentQuery = gql`
  mutation DeleteFinancialTransactionComment(
    $idFinancialTransactionComment: Int!
  ) {
    deleteFinancialTransactionComment(
      idFinancialTransactionComment: $idFinancialTransactionComment
    )
  }
`;

export const updateFinancialTransactionCommentQuery = gql`
  mutation UpdateFinancialTransactionComment(
    $data: UpdateFinancialTransactionCommentInput!
  ) {
    updateFinancialTransactionComment(data: $data) {
      data {
        idFinancialTransactionComment
      }
    }
  }
`;
