import { SatForeignTradeSupplier } from '../interfaces';

/* eslint-disable no-shadow */
export enum ForeignTradeSupplierContentReducerActionKind {
  SET_PURCHASE_OTHER_EXPENSES_TOTAL,
  SET_SELLER_OTHER_EXPENSES_TOTAL,
  CHANGE_SELLER_OTHER_EXPENSES_TOTAL_LOADING,
  CHANGE_PURCHASE_OTHER_EXPENSES_TOTAL_LOADING,
  SET_PURCHASE_ADVANCE_PAID_TOTAL,
  SET_SELLER_ADVANCE_RECEIVED_TOTAL,
}

type PurchaseAdvancePaidTotal = {
  totalAmount: number;
};

type SellerAdvanceReceivedTotal = {
  totalAmount: number;
  totalPercentage: number;
};

export type PurchaseOtherExpensesTotalReducerObject = {
  totalOtherExpenses: number;
  totalOnMargin: number;
  totalOutOfMargin: number;
  totalDiscountFromSupplier: number;
  totalPayToSupplier: number;
};

export type SellerOtherExpensesTotalReducerObject = {
  totalOtherExpenses: number;
  totalOnMargin: number;
  totalOutOfMargin: number;
  totalCreditToClient: number;
  totalForApportionment: number;
};

export type ForeignTradeSupplierContentReducer = {
  purchaseOtherExpensesTotal?: PurchaseOtherExpensesTotalReducerObject;
  sellerOtherExpensesTotal?: SellerOtherExpensesTotalReducerObject;
  sellerOtherExpensesTotalLoading?: boolean;
  purchaseOtherExpensesTotalLoading?: boolean;
  totalWithApportionment?: number;
  estimatedNetMargin?: number;
  netMargin?: number;
  estimatedNetProfit?: number;
  netProfit?: number;
  purchaseAdvancePaidTotal?: PurchaseAdvancePaidTotal;
  sellerAdvanceReceivedTotal?: SellerAdvanceReceivedTotal;
};

export type ForeignTradeSupplierContentReducerAction = {
  type: ForeignTradeSupplierContentReducerActionKind;
  payload?: {
    satForeignTradeSupplier?: SatForeignTradeSupplier;
    purchaseOtherExpensesTotal?: PurchaseOtherExpensesTotalReducerObject;
    sellerOtherExpensesTotal?: SellerOtherExpensesTotalReducerObject;
    purchaseAdvancePaidTotal?: PurchaseAdvancePaidTotal;
    sellerAdvanceReceivedTotal?: SellerAdvanceReceivedTotal;
    sunWithApportionmentTotalSum?: number;
  };
};

export interface IFinancialFileData {
  idSatAttachment?: number;
  idAttachment2?: {
    url?: string;
    nameGivenByUser?: string;
    createdAt?: string;
    idType?: number;
  };
}
