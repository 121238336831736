import { gql } from '@apollo/client';

export const listAllFinancialTransactionsQuery = gql`
  query ListAllFinancialTransactions(
    $data: ListAllFinancialTransactionsInput!
  ) {
    listAllFinancialTransactions(data: $data) {
      data {
        idFinancialTransaction
        status
        idPaymentSituation
        idPaymentSituation2 {
          description
        }
        reference
        idFinancialAccount2 {
          idCompany2 {
            name
          }
          idCurrency2 {
            abbreviation
          }
        }
        paymentForecast
        competencyRegime
        cashflowRegime
        amount
        amountUSD
        idFinancialTransactionCategory2 {
          name
          group
        }
        idCountry2 {
          name
        }
        idCostCenterByCountry2 {
          name
        }
        idClient2 {
          name
        }
        idSupplier2 {
          name
        }
        product
        description
        idSatForeignTrade
        idSatForeignTrade2 {
          idSat
          shipmentStatus
          satForeignTradeNumber
        }
        billRate
        idBeneficiary2 {
          name
        }
        hasSystemGeneratedComment
        idSatForeignTradeSupplier2 {
          idSatForeignTradeSupplier
          idStatusPurchasePayment2 {
            idDomain
            description
          }
          idStatusSellingPayment2 {
            idDomain
            description
          }
        }
        reconciledAt
      }
      items
    }
  }
`;

export const getSumDataFromFinancialTransactionsQuery = gql`
  query GetSumDataFromFinancialTransactionsList(
    $data: ListAllFinancialTransactionsFieldsSearch
  ) {
    getSumDataFromFinancialTransactionsList(data: $data) {
      amount
      amountUSD
    }
  }
`;

export const exportFinancialTransactionsQuery = gql`
  query exportFinancialTransactionsList(
    $data: ExportFinancialTransactionsListInput!
  ) {
    exportFinancialTransactionsList(data: $data)
  }
`;

export const deleteFinancialTransactionMutation = gql`
  mutation DeleteFinancialTransactions($financialTransactionsIds: [Int]!) {
    deleteFinancialTransactions(
      financialTransactionsIds: $financialTransactionsIds
    )
  }
`;
