/* eslint-disable no-shadow */
import { Tag, TagProps } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useEffect } from 'react';
import { IoMdAttach } from 'react-icons/io';
import { IDomain } from '../../interfaces/IDomain';
import { convertDate } from '../../utils/convertDate';
import RoundUserAvatar from '../RoundUserAvatar';
import StatusTag from '../StatusTag';

import { Actions, CommentContent, Container } from './styles';
import { useAuth } from '../../hooks/useAuth';

interface ICommonTagProps extends TagProps {
  isRestrictInfo?: boolean;
  isGeneratedSystem?: boolean;
  isStatusCfr?: boolean;
}

export interface ICommentProps {
  idComment: number;
  /**
   * URL da imagem do usuario
   */
  userImageUrl?: string;

  /**
   * Nome do usuario
   */
  userName: string;

  /**
   * Data de criacao do comentario
   */
  creation: Date;

  editedData?: {
    /**
     * Data de edicao do comentario
     */
    editedAt?: Date;

    /**
     * Nome do usuario que editou o comentario
     */
    editedBy?: string;
  };

  /**
   * URL de attachment do comentário
   */
  attachmentUrl?: string;

  /**
   * Informacao exibida na tag do comentario
   */
  domainTag?: IDomain;

  /**
   * Tag sem vinculo com domain
   */
  commonTag?: ICommonTagProps;

  /**
   * Acao de editar o comentario
   */
  onEdit?(): void;

  /**
   * Acao de deletar o comentario
   */
  onDelete?(): void;

  /**
   * Acao de responder o comentario
   */
  onReply?(): void;

  /**
   * Acao de responder todos os usuarios do comentario
   */
  onReplyAll?(): void;

  idCreatedBy: number;
  highlighted?: boolean;
}

enum TypeOfComment {
  RESTRICT_INFO = 'restrictInfo',
  GENERATED_SYSTEM = 'generatedSystem',
  STATUS_RNC = 'statusRnc',
}

type RenderCommonTagPropertiesResponse = {
  severity?: 'warning' | undefined;
  value: string;
};

const Comment: React.FC<ICommentProps> = ({
  idComment,
  userName,
  userImageUrl,
  attachmentUrl,
  creation,
  children,
  domainTag,
  commonTag,
  editedData,
  onEdit,
  onDelete,
  onReply,
  onReplyAll,
  idCreatedBy,
  highlighted,
}) => {
  const { idUser } = useAuth();

  const currentUserCreatedComment = idCreatedBy === idUser;

  function renderCommonTagProperties(
    tagType: string,
  ): RenderCommonTagPropertiesResponse | undefined {
    if (commonTag?.isRestrictInfo && tagType === TypeOfComment.RESTRICT_INFO) {
      return {
        severity: 'warning',
        value: 'Restrict Info',
      };
    }

    if (
      commonTag?.isGeneratedSystem &&
      tagType === TypeOfComment.GENERATED_SYSTEM
    ) {
      return { value: 'Automatic' };
    }

    return undefined;
  }

  const editedOnText = `${
    editedData?.editedAt ? convertDate(editedData.editedAt) : ''
  }${editedData?.editedBy ? ` by ${editedData?.editedBy}` : ''}`;

  const tooltipClassName = `edited-comment-info-${idComment}`;

  useEffect(() => {
    function scrollToHighlightedComment() {
      if (highlighted) {
        const element = document.getElementById(idComment.toString());
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }

    scrollToHighlightedComment();
  }, [highlighted, idComment]);

  return (
    <Container
      id={idComment.toString()}
      className="s-comment"
      highlighted={highlighted}
    >
      <Tooltip target={`.${tooltipClassName}`} />
      <span className="user-avatar-wrapper">
        <RoundUserAvatar
          image={userImageUrl ?? ''}
          userFullName={userName}
          imageAlt={userName}
        />
      </span>

      <CommentContent>
        <div className="comment-header">
          <p className="user-name">{userName}</p>
          <p>{convertDate(creation)}</p>
          {editedData && (
            <p
              className={tooltipClassName}
              data-pr-position="top"
              data-pr-tooltip={editedOnText}
            >
              Edited
            </p>
          )}
          {domainTag && (
            <StatusTag
              domain={domainTag.idDomain}
              status={domainTag.description}
            />
          )}
          <div className="commonTags">
            {commonTag?.isRestrictInfo && (
              <Tag
                {...commonTag}
                {...renderCommonTagProperties('restrictInfo')}
              />
            )}
            {commonTag?.isGeneratedSystem && (
              <Tag
                {...commonTag}
                {...renderCommonTagProperties('generatedSystem')}
              />
            )}
            {commonTag?.value && <Tag {...commonTag} />}
          </div>
        </div>
        <div className="comment-text">{children}</div>

        <Actions>
          {!commonTag?.isGeneratedSystem && (
            <>
              {currentUserCreatedComment && !!onEdit && (
                <span>
                  <button
                    className="text-sm p-0"
                    type="button"
                    onClick={() => onEdit()}
                  >
                    Edit
                  </button>
                </span>
              )}

              {currentUserCreatedComment && !!onDelete && (
                <span>
                  <button
                    className="text-sm p-0"
                    type="button"
                    onClick={() => onDelete()}
                  >
                    Delete
                  </button>
                </span>
              )}
            </>
          )}

          {!!onReply && (
            <span>
              <button
                className="text-sm p-0"
                type="button"
                onClick={() => onReply()}
              >
                Reply
              </button>
            </span>
          )}

          {!!onReplyAll && (
            <span>
              <button
                className="text-sm p-0"
                type="button"
                onClick={() => onReplyAll()}
              >
                Reply All
              </button>
            </span>
          )}

          {attachmentUrl && (
            <span>
              <a
                href={attachmentUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="attachment"
              >
                <IoMdAttach />
                Attachment
              </a>
            </span>
          )}
        </Actions>
      </CommentContent>
    </Container>
  );
};

export default Comment;
